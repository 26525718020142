import type { RefObject } from 'react';
import { useEffect, useRef } from 'react';

const useCursorToEnd = <T extends HTMLInputElement | HTMLTextAreaElement>(): RefObject<T> => {
    const ref = useRef<T>(null);

    useEffect(() => {
        const setCursorToEnd = () => {
            const element = ref.current;
            if (element) {
                const length = element.value.length;
                element.setSelectionRange(length, length);
            }
        };

        const element = ref.current;
        if (element) {
            element.addEventListener('focus', setCursorToEnd);
            return () => {
                element.removeEventListener('focus', setCursorToEnd);
            };
        }
        return () => {};
    }, []);

    return ref;
};

export default useCursorToEnd;
