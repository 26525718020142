import { EntryScreen } from '@/components/EntryScreen';
import { Button } from '@/components/ui/button/button';
import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@ui/input';
import strings from 'lib/strings';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
const pathname = '/auth/login';

const isToast = (error: string) => ['auth_error', 'unconfigured'].includes(error);

const Login = () => {
    const [searchParams] = useSearchParams();
    const error = searchParams.get('error');
    const errorConfig = useMemo(
        () => (error ? { type: isToast(error) ? 'toast' : 'inline', msg: strings.login[error] } : null),
        [error],
    );
    const onEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchParams.set('email', event.target.value);
    };

    useEffect(() => {
        if (errorConfig && errorConfig.type === 'toast') {
            toast.error(errorConfig.msg);
        }
    }, [errorConfig]);

    const handleLogin = () => {
        searchParams.delete('error');
        window.location.href = `${pathname}?${searchParams.toString()}`;
    };

    return (
        <EntryScreen>
            <CardHeader>
                <CardTitle className="text-center">Log in to Console</CardTitle>
            </CardHeader>
            <CardContent>
                <form className="gap-sm flex flex-col" onSubmit={e => e.preventDefault()}>
                    <Input
                        id="email"
                        name="email"
                        defaultValue={searchParams.get('email') ?? ''}
                        onChange={onEmailInput}
                        required
                        placeholder="Enter your email address"
                        className="placeholder:text-center"
                        autoComplete="off"
                        data-testid="email-input"
                    />
                    <div>
                        {errorConfig?.type === 'inline' && (
                            <div className="text-center text-red-900">{errorConfig.msg}</div>
                        )}
                    </div>
                    <div className="flex flex-col gap-lg">
                        <Button
                            mode="dark"
                            variant="grey"
                            className="w-full"
                            onClick={handleLogin}
                            data-testid="continue-button"
                        >
                            Continue &rarr;
                        </Button>
                    </div>
                </form>
            </CardContent>
        </EntryScreen>
    );
};

export default Login;
