import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '@ui/breadcrumb';
import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/tooltip';
import { PanelLeftOpen } from 'lucide-react';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { cn } from '@/lib/styling';
import { useApp } from '@/stores/useApp';

import { Badge } from '@/components/ui/badge/badge';
import { Button } from '@/components/ui/button/button';

export interface Crumb {
    label?: ReactNode;
    href?: string;
    bold?: boolean;
    seperator?: ReactNode;
}

interface BrowProps {
    data?: Crumb[];
    title?: string;
    icon?: ReactNode;
    actions?: ReactNode[];
    resourceCount?: number;
}

export const Brow = ({ data, title, icon, actions, resourceCount }: BrowProps) => {
    const { setApp, app } = useApp();

    return (
        <div className="gap-md p-lg bg-bg border-grey sticky top-0 z-20 flex h-12 items-center justify-between border-b-[0.5px]">
            <div className="gap-md flex items-center">
                {!app.isNavbarOpen && (
                    <div className="flex items-center">
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button
                                    mode="borderless"
                                    size="sm"
                                    className="text-body-subtle w-6 rounded-full"
                                    onClick={() => {
                                        setApp({ isNavbarOpen: true });
                                        setApp({ isNavbarHovered: false });
                                    }}
                                    onMouseEnter={() => setApp({ isNavbarHovered: true })}
                                    onMouseLeave={() => setApp({ isNavbarHovered: false })}
                                >
                                    <PanelLeftOpen className="shrink-0 size-3.5" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent side="right">Expand nav</TooltipContent>
                        </Tooltip>
                        <div
                            className="fixed left-0 top-0 -z-10 h-14 w-12"
                            onClick={() => {
                                setApp({ isNavbarOpen: true });
                                setApp({ isNavbarHovered: false });
                            }}
                            onMouseEnter={() => setApp({ isNavbarHovered: true })}
                            onMouseLeave={() => setApp({ isNavbarHovered: false })}
                        />
                        {app.isNavbarHovered && (
                            <div
                                className="fixed left-12 top-0 h-14 w-[208px]"
                                onMouseEnter={() => setApp({ isNavbarHovered: true })}
                                onMouseLeave={() => setApp({ isNavbarHovered: false })}
                            />
                        )}
                    </div>
                )}
                <div className="flex gap-md items-center">
                    {icon}
                    {title && <h1 className="text-base">{title}</h1>}
                    {resourceCount !== undefined && <Badge>{resourceCount}</Badge>}
                </div>
                {data && (
                    <Breadcrumb>
                        <BreadcrumbList>
                            {data.map((crumb, i) => {
                                // if bold is passed in, then use that to drive the boldness and not position of crumb
                                const isBold = crumb.bold !== undefined ? crumb.bold : i === data.length - 1;
                                const labelClassName = cn(
                                    'line-clamp-1',
                                    isBold && 'font-medium text-body-grey-primary',
                                );

                                return (
                                    <span key={i} className="gap-sm flex items-center">
                                        <BreadcrumbItem>
                                            <BreadcrumbLink asChild>
                                                {crumb.href ? (
                                                    <Link className={labelClassName} to={crumb.href ?? ''}>
                                                        {crumb.label}
                                                    </Link>
                                                ) : (
                                                    <div className={labelClassName}>{crumb.label}</div>
                                                )}
                                            </BreadcrumbLink>
                                        </BreadcrumbItem>
                                        {i < data.length - 1 ? (
                                            !crumb.seperator ? (
                                                <BreadcrumbSeparator />
                                            ) : (
                                                crumb.seperator
                                            )
                                        ) : null}
                                    </span>
                                );
                            })}
                        </BreadcrumbList>
                    </Breadcrumb>
                )}
            </div>
            <div>
                {actions && actions.length > 0 && (
                    <div className="flex items-center gap-x-4">
                        {actions.map((action, index) => (
                            <div key={index}>{action}</div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
