import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { PostIntegrationSettingsRequest, PostIntegrationSettingsResponse } from 'lib/models/integrations';

export const useIntegrationSettingPost = () => {
    const queryClient = useQueryClient();
    return useMutation<PostIntegrationSettingsResponse, DefaultError, PostIntegrationSettingsRequest>({
        url: '/api/v1/integrations/settings',
        method: 'POST',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: ['/api/v1/integrations/status'] });
        },
    });
};
