import { Separator } from '@/components/ui/separator/separator';
import type { Setting } from '@pages/settings/types/Settings';
import kebabCase from 'lodash-es/kebabCase';
import { useParams } from 'react-router-dom';

const SettingsContent = ({ settings }: { settings: Setting[] }) => {
    const { group, setting, subsetting } = useParams();

    const selectedGroup = settings.find(g => kebabCase(g.label) === group);
    if (!selectedGroup) {
        return null;
    }

    const item = selectedGroup.children?.find(
        s => (!setting && s.index) || kebabCase(s.pathname ?? s.label) === setting,
    );
    if (!item) {
        return null;
    }

    const { Component, label, description, LabelAction } = item;

    const showHeader = !subsetting || setting === 'help-desk-configuration';

    if (Component) {
        return (
            <div className="m-auto gap-2xl py-4xl flex max-w-screen-sm flex-col relative">
                {showHeader && (
                    <div className="gap-2xl flex flex-col">
                        <div>
                            <div className="flex items-center">
                                <h1 className="text-xl">{label}</h1>
                                {LabelAction && <LabelAction />}
                            </div>
                            <span className="text-body-subtle-hover text-base">
                                {description && <p>{description}</p>}
                            </span>
                        </div>
                        <Separator />
                    </div>
                )}
                <Component />
            </div>
        );
    }

    return null;
};

export default SettingsContent;
