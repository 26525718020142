import { baseFetch } from '@lib/baseFetch';
import type {
    DefaultError,
    UseMutationOptions as PrimitiveUseMutationOptions,
    UseMutationResult,
} from '@tanstack/react-query';
import { useMutation as useMutationPrimitive } from '@tanstack/react-query';
import { ORG_ID_HEADER_KEY } from 'lib/constants';
import { type ExternalToast, toast } from 'sonner';

import { useUser } from '@/stores/useUser';

interface ToastConfig {
    message: string;
    disable?: boolean;
    options?: ExternalToast;
}

interface UseMutationOptions<TData, TError, TVariables>
    extends Omit<PrimitiveUseMutationOptions<TData, TError, TVariables>, 'mutationFn'> {
    url: string;
    method: 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    successToast?: ToastConfig;
    errorToast?: ToastConfig;
}

export const useMutation = <TData = unknown, TError = DefaultError, TVariables = unknown>(
    options: UseMutationOptions<TData, TError, TVariables>,
): UseMutationResult<TData, TError, TVariables> => {
    const userStore = useUser.getState();
    const orgId = userStore.user?.orgMemberships[0].orgId;
    const headers: Record<string, string> = {};
    if (orgId) {
        headers[ORG_ID_HEADER_KEY] = orgId;
    }

    const { url, method, onError, onSuccess, errorToast, successToast, ...rest } = options;
    return useMutationPrimitive({
        retry: 0,
        mutationFn: async body => {
            const opts = { headers, method, body };

            return baseFetch<TData, TVariables>(url, opts);
        },
        onError: (e, v, c) => {
            if (onError) {
                onError(e, v, c);
            }

            if (!errorToast?.disable) {
                if (errorToast) {
                    toast.error(errorToast.message, errorToast.options);
                } else {
                    toast.error('Something went wrong');
                }
            }
        },
        onSuccess: (d, v, c) => {
            if (onSuccess) {
                onSuccess(d, v, c);
            }

            if (successToast?.message) {
                toast.success(successToast.message, successToast.options);
            }
        },
        ...rest,
    });
};
