import { Avatar, AvatarFallback, AvatarImage, type AvatarImgVariantRounded, type AvatarVariantSize } from '@ui/avatar';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Chip } from './Chip';

import { initials } from '@/lib/initials';
import { cn } from '@/lib/styling';

const entities = ['users', 'groups', 'apps', 'playbooks'] as const;

export interface ResLinkProps {
    entity?: (typeof entities)[number];
    id?: string;
    src?: string | null;
    label?: string | null;
    badge?: ReactNode;
    size?: AvatarVariantSize;
    rounded?: AvatarImgVariantRounded;
    fallback?: ReactNode;
    bold?: boolean;
    className?: string;
    description?: string;
    avatarOnly?: boolean;
    disableLink?: boolean;
    to?: string;
    chip?: boolean;
    clamp?: boolean;
}

export const ResLink = ({
    entity,
    id,
    src,
    label: initLabel,
    badge,
    size = 'default',
    fallback,
    rounded,
    bold,
    className,
    description,
    avatarOnly,
    disableLink,
    to,
    chip,
    clamp,
}: ResLinkProps) => {
    const label = initLabel ?? '';
    const initialString = ['groups', 'apps'].includes(entity ?? '') ? label.replaceAll(' ', '') : label;
    const boldStyles = bold ? 'font-medium' : '';
    const clampStyles = clamp ? 'overflow-hidden text-ellipsis line-clamp-1' : '';

    const imgSrc = () => {
        if (entity === 'apps' && id) {
            return `/api/v1/apps/${id}/logo`;
        }
        return src;
    };

    const makeLinkTo = () => {
        if (to) {
            return to;
        }
        return `/${entity}/${id}`;
    };

    const avatarComponent = (
        <div className="relative flex items-center justify-center">
            {badge && (
                <div className="size-3.5 z-10 absolute -bottom-sm -right-sm bg-bg rounded-full flex items-center justify-center">
                    {badge}
                </div>
            )}
            <Avatar size={chip ? 'sm' : size}>
                <AvatarImage src={imgSrc() ?? undefined} rounded={rounded} />
                {/* The bg-transparent logic allows us to fully customize what gets passed into the AvatarFallback without being tied to the default grey circle that the initials appear in */}
                <AvatarFallback className={fallback ? 'bg-transparent overflow-visible' : ''}>
                    {fallback ?? initials(initialString)}
                </AvatarFallback>
            </Avatar>
        </div>
    );

    if (avatarOnly) {
        return avatarComponent;
    }

    const labelComponent = <span className={cn(boldStyles, clampStyles)}>{label}</span>;

    const resLink = (
        <>
            {avatarComponent}
            {description ? (
                <div className="flex flex-col">
                    <span className={cn('w-full truncate', boldStyles)}>{labelComponent}</span>
                    <span className="text-body-subtle">{description}</span>
                </div>
            ) : (
                <span className={cn('w-full truncate', boldStyles)}>{labelComponent}</span>
            )}
        </>
    );

    const wrapperClassName = cn('gap-md flex items-center max-w-full !overflow-visible', className);
    const chipWrapperClassName = cn('!overflow-visible w-fit', className);

    return entity && id && !disableLink ? (
        <Link to={makeLinkTo()} className={chip ? chipWrapperClassName : wrapperClassName}>
            {chip ? <Chip>{resLink}</Chip> : resLink}
        </Link>
    ) : (
        <div className={chip ? chipWrapperClassName : wrapperClassName}>{chip ? <Chip>{resLink}</Chip> : resLink}</div>
    );
};
