import { cva } from 'class-variance-authority';

const rootVariants = cva(
    'focus-visible:ring-ring focus-visible:ring-offset-background data-[state=checked]:bg-bg-grey-secondary data-[state=unchecked]:bg-bg-grey-primary peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
    {
        variants: {
            size: {
                default: 'h-6 w-11',
                sm: 'h-5 w-9',
            },
        },
        defaultVariants: {
            size: 'default',
        },
    },
);

const thumbVariants = cva(
    'bg-bg pointer-events-none block rounded-full shadow-lg ring-0 transition-transform data-[state=unchecked]:translate-x-0',
    {
        variants: {
            size: {
                default: 'size-5 data-[state=checked]:translate-x-5',
                sm: 'size-4 data-[state=checked]:translate-x-4',
            },
        },
        defaultVariants: {
            size: 'default',
        },
    },
);

export { rootVariants, thumbVariants };
