import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { avatarImgVariants, avatarVariants } from '@ui/avatar';
import type { VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/styling';

export interface AvatarProps
    extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>,
        VariantProps<typeof avatarVariants> {}

const Avatar = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, AvatarProps>(
    ({ className, size, ...props }, ref) => (
        <AvatarPrimitive.Root ref={ref} className={cn(avatarVariants({ size }), className)} {...props} />
    ),
);
Avatar.displayName = AvatarPrimitive.Root.displayName;

export interface AvatarImageProps
    extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>,
        VariantProps<typeof avatarImgVariants> {}

const AvatarImage = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Image>, AvatarImageProps>(
    ({ className, rounded, ...props }, ref) => (
        <AvatarPrimitive.Image
            ref={ref}
            className={cn(avatarImgVariants({ rounded, bgColor: 'default' }), className)}
            {...props}
        />
    ),
);
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

export const getAvatarColor = (s: string) => {
    let hash = 0;
    for (const char of s) {
        hash = (hash << 5) - hash + char.charCodeAt(0);
        hash |= 0;
    }

    const n = Math.abs(hash) % 20;

    if (n === 0) {
        return 'tomato';
    } else if (n === 1) {
        return 'red';
    } else if (n === 2) {
        return 'crimson';
    } else if (n === 3) {
        return 'pink';
    } else if (n === 4) {
        return 'plumb';
    } else if (n === 5) {
        return 'purple';
    } else if (n === 6) {
        return 'violet';
    } else if (n === 7) {
        return 'indigo';
    } else if (n === 8) {
        return 'blue';
    } else if (n === 9) {
        return 'cyan';
    } else if (n === 10) {
        return 'teal';
    } else if (n === 11) {
        return 'green';
    } else if (n === 12) {
        return 'grass';
    } else if (n === 13) {
        return 'orange';
    } else if (n === 14) {
        return 'brown';
    } else if (n === 15) {
        return 'sky';
    } else if (n === 16) {
        return 'mint';
    } else if (n === 17) {
        return 'lime';
    } else if (n === 18) {
        return 'yellow';
    } else {
        return 'amber';
    }
};

export interface AvatarFallbackProps
    extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>,
        VariantProps<typeof avatarImgVariants> {}

const AvatarFallback = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Fallback>, AvatarFallbackProps>(
    ({ className, rounded, children, ...props }, ref) => {
        let bgColor: VariantProps<typeof avatarImgVariants>['bgColor'] = 'default';
        if (typeof children === 'string') {
            bgColor = getAvatarColor(children);
        }

        return (
            <AvatarPrimitive.Fallback
                ref={ref}
                className={cn(
                    'flex size-full items-center justify-center font-medium',
                    avatarImgVariants({ rounded, bgColor }),
                    className,
                )}
                {...props}
            >
                {children}
            </AvatarPrimitive.Fallback>
        );
    },
);
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarImage, AvatarFallback };
