import { cva } from 'class-variance-authority';

const badgeVariants = cva(
    'focus:ring-ring px-md inline-flex gap-sm min-w-[22px] items-center justify-center rounded-full py-0.5 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2',
    {
        variants: {
            variant: {
                default: 'border-grey bg-bg border',
                grey: '',
                red: '',
                yellow: '',
                green: '',
                blue: '',
            },
            mode: {
                light: '',
                dark: '',
            },
        },
        compoundVariants: [
            {
                variant: 'grey',
                mode: 'light',
                className: 'bg-bg-grey-primary border-grey border-[0.5px]',
            },
            {
                variant: 'red',
                mode: 'light',
                className: 'bg-bg-red-primary text-body-red-primary border-red border-[0.5px]',
            },
            {
                variant: 'yellow',
                mode: 'light',
                className: 'bg-bg-yellow-primary text-body-yellow-primary border-yellow border-[0.5px]',
            },
            {
                variant: 'green',
                mode: 'light',
                className: 'bg-bg-green-primary text-body-green-primary border-green border-[0.5px]',
            },
            {
                variant: 'blue',
                mode: 'light',
                className: 'bg-bg-blue-primary text-body-blue-primary border-blue border-[0.5px]',
            },
            {
                variant: 'grey',
                mode: 'dark',
                className: 'bg-bg-grey-secondary text-body-grey-secondary',
            },
            {
                variant: 'red',
                mode: 'dark',
                className: 'bg-bg-red-secondary text-body-red-secondary',
            },
            {
                variant: 'yellow',
                mode: 'dark',
                className: 'bg-bg-yellow-secondary text-body-yellow-secondary',
            },
            {
                variant: 'green',
                mode: 'dark',
                className: 'bg-bg-green-secondary text-body-green-secondary',
            },
            {
                variant: 'blue',
                mode: 'dark',
                className: 'bg-bg-blue-secondary text-body-blue-secondary',
            },
        ],
        defaultVariants: {
            variant: 'default',
            mode: 'light',
        },
    },
);

export { badgeVariants };
