import { getProviderAssets } from 'lib/3p';
import type { IntegrationId } from 'lib/integration';
import { type ControlProps, type GroupBase, components } from 'react-select';

export const AppControl =
    (integration: IntegrationId) =>
    <Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
        children,
        ...props
    }: ControlProps<Option, IsMulti, Group>) => {
        const { logo, name } = getProviderAssets(integration);
        return (
            <components.Control {...props}>
                <img src={logo} alt={name} className="w-4 h-4 ml-2" />
                {children}
            </components.Control>
        );
    };
