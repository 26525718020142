import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type {
    PlaybookResponse,
    PlaybookTemplate,
    PlaybookUpdatePermissionsSchemaType,
    PlaybookUpdateSchemaType,
} from 'lib/models/playbook';
import { useNavigate } from 'react-router-dom';

import { PLAYBOOKS, type PLAYBOOK_DETAILS_TYPE } from '@/routes/paths';
import type { Playbook } from 'lib/prisma/types';
import { ONBOARDING_SUBSCRIBE_MUTATION_KEY } from '../useSubscribeOnboardingFlow';

export const usePlaybookCreate = () => {
    const navigate = useNavigate();
    return useMutation<PlaybookResponse, DefaultError>({
        url: `/api/v1/playbooks`,
        method: 'POST',
        onSuccess: async ({ playbook }) => {
            navigate(`${PLAYBOOKS}/${playbook.slug}` as PLAYBOOK_DETAILS_TYPE);
        },
        successToast: {
            message: 'Playbook draft created',
        },
    });
};

export const usePlaybookDelete = (slug: string) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation<PlaybookResponse, DefaultError, void>({
        url: `/api/v1/playbooks/${slug}`,
        method: 'DELETE',
        mutationKey: [ONBOARDING_SUBSCRIBE_MUTATION_KEY],
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: [`/api/v1/playbooks`], exact: true });
            await navigate(PLAYBOOKS);
        },
        successToast: {
            message: 'Playbook deleted',
        },
    });
};

export const usePlaybookUpdate = (slug: string) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation<PlaybookResponse, DefaultError, PlaybookUpdateSchemaType>({
        url: `/api/v1/playbooks/${slug}`,
        method: 'PUT',
        onSuccess: async ({ playbook }) => {
            if (playbook.slug !== slug) {
                navigate(`${PLAYBOOKS}/${playbook.slug}` as PLAYBOOK_DETAILS_TYPE);
            } else {
                await queryClient.refetchQueries({ queryKey: [`/api/v1/playbooks/${playbook.slug}`], exact: true });
                await queryClient.refetchQueries({ queryKey: [`/api/v1/playbooks`], exact: true });
            }
        },
    });
};

export const usePlaybookUpdatePermissions = (slug: string) => {
    const queryClient = useQueryClient();
    return useMutation<{ playbook: Playbook }, DefaultError, PlaybookUpdatePermissionsSchemaType>({
        url: `/api/v1/playbooks/${slug}/permissions`,
        method: 'PUT',
        onSuccess: async ({ playbook }) => {
            await queryClient.refetchQueries({ queryKey: [`/api/v1/playbooks/${playbook.slug}`], exact: true });
            await queryClient.refetchQueries({ queryKey: [`/api/v1/playbooks`], exact: true });
        },
    });
};

export const usePlaybookUnpublish = (slug: string) => {
    const queryClient = useQueryClient();
    return useMutation<PlaybookResponse, DefaultError, void>({
        url: `/api/v1/playbooks/${slug}/unpublish`,
        method: 'PUT',
        mutationKey: [ONBOARDING_SUBSCRIBE_MUTATION_KEY],
        onSuccess: async ({ playbook }) => {
            await queryClient.refetchQueries({ queryKey: [`/api/v1/playbooks/${playbook.slug}`], exact: true });
        },
        successToast: {
            message: 'Playbook moved to draft',
        },
    });
};

export const usePlaybookPublish = (slug: string) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation<PlaybookResponse, DefaultError, PlaybookUpdateSchemaType>({
        url: `/api/v1/playbooks/${slug}/publish`,
        method: 'PUT',
        mutationKey: [ONBOARDING_SUBSCRIBE_MUTATION_KEY],
        onSuccess: async ({ playbook }) => {
            if (playbook.slug !== slug) {
                navigate(`${PLAYBOOKS}/${playbook.slug}` as PLAYBOOK_DETAILS_TYPE, { replace: true });
            } else {
                await Promise.all([
                    queryClient.refetchQueries({ queryKey: [`/api/v1/playbooks/${playbook.slug}`], exact: true }),
                    queryClient.refetchQueries({ queryKey: [`/api/v1/playbooks/`], exact: true }),
                ]);
            }
        },
        successToast: {
            message: 'Playbook published',
        },
    });
};

export const usePlaybookTemplateClone = () => {
    const navigate = useNavigate();
    return useMutation<PlaybookResponse, DefaultError, PlaybookTemplate>({
        url: `/api/v1/playbooks/template/clone`,
        method: 'POST',
        mutationKey: [ONBOARDING_SUBSCRIBE_MUTATION_KEY],
        onSuccess: async ({ playbook }) => {
            navigate(`${PLAYBOOKS}/${playbook.slug}` as PLAYBOOK_DETAILS_TYPE, { replace: true });
        },
        successToast: {
            message: 'Playbook template cloned',
        },
    });
};
