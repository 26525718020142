import type { OrgOnboardingType } from 'lib/models/org';
import type { OnboardingStep } from './useOnboardinSteps';

export const numberOfCompletedSteps = (onboarding: OrgOnboardingType) => {
    return Object.values(onboarding).filter(step => typeof step === 'boolean' && step).length;
};

export const getLastUncompletedStep = (steps: OnboardingStep[]) => {
    return steps.find(step => !step.completed);
};

// Right now we are just returnign booleans for each step, so we can figure out how many steps we have completed
export const getTotalSteps = (onboarding: OrgOnboardingType) => {
    return Object.values(onboarding).filter(step => typeof step === 'boolean').length;
};
