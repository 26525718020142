import { useCallback } from 'react';

import { useEventListener } from '../hooks/useEventListener';

const RegisterGlobalListeners = () => {
    const handleFirstTab = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Tab') {
            document.body.classList.add('tabbing');
        }
    }, []);

    const handleMouseDownOnce = useCallback(() => {
        document.body.classList.remove('tabbing');
    }, []);

    useEventListener('keydown', handleFirstTab);
    useEventListener('mousedown', handleMouseDownOnce);

    return null;
};

export default RegisterGlobalListeners;
