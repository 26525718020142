import type { VariantProps } from 'class-variance-authority';
import type * as React from 'react';

import { cn } from '@/lib/styling';

import loaderVariants from './loaderVariants';

export interface LoadingSpinnerProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof loaderVariants> {}

const Loader = ({ className, size, ...props }: LoadingSpinnerProps) => {
    return (
        <div className={cn('flex items-center justify-center', className)} {...props}>
            <div className={cn('relative', loaderVariants({ size }))}>
                <div className="border-grey-300 absolute left-0 top-0 size-full rounded-full border-2 opacity-20" />
                <div className="border-r-grey-secondary border-t-grey-secondary absolute left-0 top-0 size-full animate-spin rounded-full border-2 border-b-transparent border-l-transparent" />
            </div>
        </div>
    );
};

export default Loader;
