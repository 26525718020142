import { useMutation } from '@lib/hooks/useMutation';
import type { DefaultError } from '@tanstack/react-query';
import type { DefaultPolicySettings } from 'lib/models/app_policy';

export const useDefaultPolicySettingsPut = () => {
    return useMutation<DefaultPolicySettings, DefaultError, DefaultPolicySettings>({
        url: `/api/v1/policies/default-settings`,
        method: 'PUT',
        successToast: {
            message: 'Settings updated',
        },
        errorToast: {
            message: 'Problem updating settings',
        },
    });
};
