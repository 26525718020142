import { cva } from 'class-variance-authority';

export const dialogVariants = cva('', {
    variants: {
        size: {
            default: 'max-w-lg',
            wide: 'max-w-[600px]',
            wider: 'max-w-[900px]',
            max: 'max-w-[80vw]',
        },
    },
    defaultVariants: {
        size: 'default',
    },
});
