import { useMutation } from '@/lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';

export const useCategoriesDelete = (categoryId: string) => {
    const queryClient = useQueryClient();
    return useMutation<void, DefaultError, void>({
        url: `/api/v1/categories/${categoryId}`,
        method: 'DELETE',
        onSuccess: async () => await queryClient.refetchQueries({ queryKey: ['/api/v1/categories'] }),
    });
};
