import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { CreateCategorySchemaType } from 'lib/models/category';

export const useCategoriesPost = () => {
    const queryClient = useQueryClient();
    return useMutation<void, DefaultError, CreateCategorySchemaType>({
        url: `/api/v1/categories`,
        method: 'POST',
        onSuccess: async () => await queryClient.refetchQueries({ queryKey: ['/api/v1/categories'] }),
    });
};
