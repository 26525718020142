import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { CategorySchemaType } from 'lib/models/category';

export const useCategoriesUpdate = (categoryId: string) => {
    const queryClient = useQueryClient();
    return useMutation<void, DefaultError, CategorySchemaType>({
        url: `/api/v1/categories/${categoryId}`,
        method: 'PUT',
        onSuccess: async () => await queryClient.refetchQueries({ queryKey: ['/api/v1/categories'] }),
    });
};
