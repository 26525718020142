import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

export const ONBOARDING_SUBSCRIBE_MUTATION_KEY = 'onboarding-subscribe';
export const ONBOARDING_QUERY_KEY = '/api/v1/org/onboarding';

/**
 * Currently we don't store a lot of state for onboarding. Everything is derived in the DB using the /onboarding endpoint.
 * When the FE is updating conditions that could make a onboarding step complete/incomplete, we needa way to refetch the onboarding
 * state. Right now a bunch of mutations are subscribing to trigger a refetch of the onboarding state.
 */
export const useSubscribeOnboardingFlow = () => {
    const queryClient = useQueryClient();

    useEffect(() => {
        const mutationCache = queryClient.getMutationCache();

        const unsubscribe = mutationCache.subscribe(data => {
            if (
                queryClient.getQueryData([ONBOARDING_QUERY_KEY]) &&
                data.type === 'updated' &&
                data.mutation?.options.mutationKey?.[0] === ONBOARDING_SUBSCRIBE_MUTATION_KEY
            ) {
                queryClient.refetchQueries({ queryKey: [ONBOARDING_QUERY_KEY] }, { cancelRefetch: false });
            }
        });

        return () => {
            unsubscribe();
        };
    }, [queryClient]);

    return;
};
