import { cn } from '@/lib/styling';
import { Card } from '@ui/card';
import { type PropsWithChildren, useEffect, useState } from 'react';
import { Toaster } from 'sonner';

interface EntryScreenProps {
    cardClassName?: string;
}
export const EntryScreen = ({ cardClassName, children }: PropsWithChildren<EntryScreenProps>) => {
    const [heightClass, setHeightClass] = useState('h-0');

    useEffect(() => {
        const timer = setTimeout(() => {
            setHeightClass('h-9');
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="bg-bg p-lg flex h-screen flex-col items-center justify-center">
            {/* eslint-disable-next-line tailwindcss/no-contradicting-classname */}
            <div className="mask-[radial-gradient(ellipse_at_center,rgba(0,0,0,1),transparent_75%)] absolute inset-0 bg-[radial-gradient(#F3F3F3_1px,transparent_0)] bg-[size:7px_7px]" />
            <div className="bg-bg-elevated border-grey z-10 flex w-full max-w-[375px] shrink flex-col rounded-lg border-[0.5px]">
                <div
                    className={cn(
                        'gap-sm text-body-subtle transition-height flex duration-200 items-center justify-center overflow-hidden ease-out',
                        heightClass,
                    )}
                >
                    Welcome to
                    <img src="/console-grey.svg" alt="Console Logo" className="mb-[2px]" />
                </div>
                <Card className={cn('shrink border-none', cardClassName)}>{children}</Card>
                <Toaster closeButton visibleToasts={1} />
            </div>
        </div>
    );
};
