import { type FlagKey, defaultFlags } from 'lib/flags';
import { useFeatureFlagEnabled, useFeatureFlagVariantKey } from 'posthog-js/react';

export function useFeatureFlag(flag: FlagKey): boolean {
    const isEnabled = useFeatureFlagEnabled(flag);
    if (isEnabled === undefined && import.meta.env.MODE === 'development') {
        return defaultFlags[flag] === true;
    }
    return !!isEnabled;
}

export function useFeatureFlagVariant(flag: FlagKey): string | boolean | undefined {
    const variantKey = useFeatureFlagVariantKey(flag);
    if (variantKey === undefined && import.meta.env.MODE === 'development') {
        return defaultFlags[flag];
    }
    return variantKey;
}
