import { Check, ChevronDown, X } from 'lucide-react';
import type { ReactElement } from 'react';
import type {
    ClassNamesConfig,
    ClearIndicatorProps,
    DropdownIndicatorProps,
    GroupBase,
    OptionProps,
    Props,
} from 'react-select';
import SelectPrimitive, { components } from 'react-select';
import {
    type AsyncPaginateProps,
    AsyncPaginate as SelectPrimitiveAsync,
    wrapMenuList,
} from 'react-select-async-paginate';

import { cn } from '@/lib/styling';
import { MenuListSelectedTop } from './MenuListSelectedTop';

export type TriggerVariant = 'default' | 'small' | 'cell' | 'kv' | 'menuSub';

const propsIsDisabled = (props: unknown) => {
    if (typeof props === 'object' && props !== null && !Array.isArray(props)) {
        return !!(props as { isDisabled?: boolean }).isDisabled;
    }
    return false;
};

const triggerVariants: Record<TriggerVariant, Record<string, string>> = {
    default: {
        container: 'hover:bg-bg-grey-primary h-8 rounded-[6px] shadow disabled:shadow-none border-grey border-[0.5px]',
        control: 'h-8',
        indicatorsContainer: 'h-8 my-auto flex-row-reverse mr-[-1px]',
        multiValue: 'h-6 min-w-6 pr-[5px] text-xs rounded-sm bg-bg-elevated gap-sm border-grey border-[0.5px]',
        multiValueRemove: 'size-3',
        singleValue: 'overflow-visible w-full text-ellipsis',
        valueContainer: 'h-8 gap-sm px-md !flex-nowrap',
    },
    small: {
        container: 'hover:bg-bg-grey-primary h-6 rounded-[6px] shadow disabled:shadow-none border-grey border-[0.5px]',
        control: 'h-6',
        indicatorsContainer: 'h-6 my-auto flex-row-reverse mr-[-1px]',
        multiValue: 'h-4 min-w-4 pr-[5px] text-xs rounded-sm bg-bg-elevated gap-sm border-grey border-[0.5px]',
        multiValueRemove: 'size-3',
        valueContainer: 'h-6 gap-sm pl-md !flex-nowrap',
        clearIndicator: 'size-6',
    },
    cell: {
        container: '',
        indicatorSeparator: 'hidden',
        multiValue: 'h-6 min-w-6 pl-sm pr-[5px] text-xs rounded-sm bg-bg-elevated gap-sm border-grey border-[0.5px]',
        multiValueRemove: 'size-3',
        valueContainer: 'gap-sm px-md py-sm',
        clearIndicator: 'size-3',
    },
    kv: {
        container: 'h-8 hover:bg-bg-grey-primary rounded-[6px]',
        control: 'h-8',
        multiValue: 'h-6 min-w-6 pr-[5px] text-xs rounded-sm bg-bg-elevated gap-sm border-grey border-[0.5px]',
        multiValueRemove: 'size-3',
        valueContainer: 'h-8 gap-sm px-md py-sm',
    },
    menuSub: {
        clearIndicator: 'rounded-sm size-6',
        container: 'w-full rounded-none shadow-none p-0 flex flex-col',
        dropdownIndicator: '!hidden',
        menu: '!relative shadow-none rounded-none border-none',
        menuList: '!p-0',
        valueContainer: 'h-6 gap-sm pl-md !flex-nowrap',
    },
};

const defaultClassNames = {
    clearIndicator: (_props: unknown, variant: TriggerVariant) =>
        cn(
            'flex items-center justify-center size-8 shrink-0 text-body-subtle hover:bg-bg-grey-primary border-l-[0.5px] border-grey rounded-r-[6px]',
            triggerVariants[variant].clearIndicator,
        ),

    container: (props: unknown, variant: TriggerVariant) =>
        cn(
            'w-full inline-flex items-center justify-center disabled:text-body-subtle whitespace-nowrap disabled:pointer-events-none',
            propsIsDisabled(props) && 'bg-bg-grey-primary',
            triggerVariants[variant].container,
        ),

    control: (_props: unknown, variant: TriggerVariant) => cn('w-full', triggerVariants[variant].control),

    dropdownIndicator: (_props: unknown, variant: TriggerVariant) =>
        cn(
            'flex items-center justify-center size-8 shrink-0 text-body-subtle text-body-grey-primary',
            triggerVariants[variant].dropdownIndicator,
        ),

    indicatorsContainer: (_props: unknown, variant: TriggerVariant) => cn(triggerVariants[variant].indicatorsContainer),

    indicatorSeparator: (_props: unknown, variant: TriggerVariant) =>
        cn('hidden', triggerVariants[variant].indicatorSeparator),

    menu: (_props: unknown, variant: TriggerVariant) =>
        cn(
            'border-[0.5px] border-grey rounded-md bg-bg shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 min-w-40',
            triggerVariants[variant].menu,
        ),

    // radix models are swallowing all the events
    menuPortal: (_props: unknown, variant: TriggerVariant) =>
        cn('pointer-events-auto !z-50', triggerVariants[variant].menuPortal),

    menuList: (_props: unknown, variant: TriggerVariant) => cn('flex flex-col p-sm', triggerVariants[variant].menuList),

    multiValue: (_props: unknown, variant: TriggerVariant) =>
        cn('shrink-0 inline-flex items-center whitespace-nowrap', triggerVariants[variant].multiValue),

    multiValueRemove: (_props: unknown, variant: TriggerVariant) =>
        cn('text-body-subtle flex items-center', triggerVariants[variant].multiValueRemove),

    option: (_props: unknown, variant: TriggerVariant) =>
        cn(
            'flex cursor-default items-center gap-sm rounded-sm px-md py-sm hover:bg-bg-overlay',
            triggerVariants[variant].option,
        ),

    placeholder: (_props: unknown, variant: TriggerVariant) =>
        cn('text-body-subtle', triggerVariants[variant].placeholder),

    singleValue: (_props: unknown, variant: TriggerVariant) => cn('', triggerVariants[variant].singleValue),

    selectContainer: (_props: unknown, variant: TriggerVariant) =>
        cn(
            'butt opacity-50 disabled:text-body-subtle disabled:bg-bg-surface disabled:pointer-events-none',
            triggerVariants[variant].container,
        ),

    valueContainer: (_props: unknown, variant: TriggerVariant) =>
        cn('flex items-center', triggerVariants[variant].valueContainer),
};

const mergeWithDefaults = <Option, IsMulti extends boolean = true, Group extends GroupBase<Option> = GroupBase<Option>>(
    variant: TriggerVariant,
    classNames?: ClassNamesConfig<Option, IsMulti, Group>,
) => {
    const merged: ClassNamesConfig<Option, IsMulti, Group> = {};

    for (const key in defaultClassNames) {
        const k = key as keyof typeof defaultClassNames;
        // @ts-expect-error - fix later. The correct type is not exporting from react-select
        merged[key] = (props: ClassNamesConfig<Option, IsMulti, Group>[typeof k]) =>
            defaultClassNames[k](props, variant);
    }

    for (const key in classNames) {
        if (key in defaultClassNames) {
            const k = key as keyof typeof defaultClassNames;
            // @ts-expect-error - fix later
            merged[k] = (props: ClassNamesConfig<Option, IsMulti, Group>[typeof k]) =>
                // @ts-expect-error - fix later
                cn(defaultClassNames[k](props, variant), classNames[k](props));
        } else {
            // @ts-expect-error - fix later
            merged[key] = props => classNames[key](props);
        }
    }

    return merged;
};

const DropdownIndicator = <Option, IsMulti extends boolean = true, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => (
    <components.DropdownIndicator {...props}>
        <ChevronDown className="size-4 text-body-subtle" />
    </components.DropdownIndicator>
);

const ClearIndicator = <Option, IsMulti extends boolean = true, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: ClearIndicatorProps<Option, IsMulti, Group>,
) => (
    <components.ClearIndicator {...props}>
        <X className="size-3.5" />
    </components.ClearIndicator>
);

const MultiOption = <Option, IsMulti extends boolean = true, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: OptionProps<Option, IsMulti, Group>,
) => {
    const { children, ...restProps } = props;

    return (
        <components.Option {...restProps}>
            <div className="items center flex justify-between">
                {children}
                {restProps.isSelected && <Check />}
            </div>
        </components.Option>
    );
};

type SelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>> = Props<
    Option,
    IsMulti,
    Group
> & {
    trigger?: TriggerVariant;
};

export function Select<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
    classNames,
    components,
    trigger = 'default',
    ...props
}: SelectProps<Option, IsMulti, Group>) {
    return (
        <SelectPrimitive
            classNames={mergeWithDefaults(trigger, classNames)}
            components={{ DropdownIndicator, ClearIndicator, ...components }}
            unstyled
            menuPortalTarget={null}
            isSearchable={false}
            closeMenuOnSelect={true}
            {...props}
        />
    );
}

export type SelectAsyncProps = <
    OptionType,
    Group extends GroupBase<OptionType>,
    Additional extends { cursor?: string },
    IsMulti extends boolean = false,
>(
    props: AsyncPaginateProps<OptionType, Group, Additional, IsMulti> & {
        trigger?: TriggerVariant;
    },
) => ReactElement;

/**
 * A select component that uses the `react-select-async-paginate` library and defaults to our styling
 */
export const SelectAsync: SelectAsyncProps = ({
    classNames,
    components: clientComponents,
    trigger = 'default',
    isMulti,
    menuPortalTarget,
    ...props
}) => {
    const mpt = menuPortalTarget === undefined ? null : menuPortalTarget;
    return (
        <SelectPrimitiveAsync
            unstyled
            isMulti={isMulti}
            classNames={mergeWithDefaults(trigger, classNames)}
            components={{
                DropdownIndicator,
                ClearIndicator,
                Option: isMulti ? MultiOption : components.Option,
                MenuList: isMulti ? wrapMenuList(MenuListSelectedTop) : wrapMenuList(components.MenuList),
                ...clientComponents,
            }}
            shouldLoadMore={(scrollHeight: number, clientHeight: number, scrollTop: number) => {
                return scrollHeight - scrollTop - clientHeight < 200;
            }}
            menuPortalTarget={mpt}
            hideSelectedOptions={!isMulti}
            closeMenuOnSelect={!isMulti}
            {...props}
        />
    );
};
