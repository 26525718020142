import type React from 'react';
import { useState } from 'react';

import { Button } from '@/components/ui/button/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog';

export interface ConfirmationProps {
    ctaLabel: string;
    title: string;
    subtitle: string;
    onClose?: () => void;
    onConfirm: () => void;
}

export const Confirmation: React.FC<React.PropsWithChildren<ConfirmationProps>> = ({
    children,
    ctaLabel,
    title,
    subtitle,
    onClose,
    onConfirm,
}) => {
    const [open, setOpen] = useState(false);

    // We need this custom handleClose because we need to wait for the dialog's exit animation to finish before calling onClose
    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            onClose && onClose();
        }, 250);
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <DialogContent className="max-w-[400px]">
                <DialogHeader className="px-xl">
                    <DialogTitle className="text-center text-base">{title}</DialogTitle>
                </DialogHeader>
                <DialogDescription className="px-xl mb-lg text-center">
                    <div>{subtitle}</div>
                    <span>Are you sure you want to delete?</span>
                </DialogDescription>
                <DialogFooter>
                    <div className="border-subtle gap-md pt-md flex w-full flex-col border-t">
                        <Button
                            className="w-full"
                            variant="red"
                            mode="dark"
                            onClick={() => {
                                handleClose();
                                onConfirm();
                            }}
                        >
                            {ctaLabel}
                        </Button>
                        <Button mode="borderless" className="w-full" onClick={handleClose}>
                            Cancel
                        </Button>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
