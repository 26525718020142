import type { DefaultPolicySettings, PolicyActionTemplateResponse, PolicyDetailsResponse } from 'lib/models/app_policy';

import { useQuery } from '@/lib/hooks/useQuery';

export const useDefaultPolicyConfig = () => {
    return useQuery<{ settings: DefaultPolicySettings | undefined }>({
        url: `/api/v1/policies/default-settings`,
    });
};

export const usePolicyActionTemplates = () => {
    return useQuery<PolicyActionTemplateResponse>({
        url: `/api/v1/policies/action-templates`,
    });
};

export const usePolicyDetails = (policyId: string | undefined) => {
    return useQuery<PolicyDetailsResponse>({
        url: `/api/v1/policies/${policyId}`,
        enabled: !!policyId,
    });
};
