import { useMutation } from '@lib/hooks/useMutation';
import type { DefaultError } from '@tanstack/react-query';
import type { UpdateEscalationConfigType } from 'lib/models/settings/escalate';

export const useUpdateErrorEscalationSettings = () => {
    return useMutation<{ config: UpdateEscalationConfigType }, DefaultError, UpdateEscalationConfigType>({
        url: `/api/v1/settings/escalate/config`,
        method: 'PUT',
        errorToast: {
            message: 'Problem updating settings',
        },
    });
};
