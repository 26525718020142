export function ErrorPage({ error }: { error: Error }) {
    return (
        <div>
            <h1 className="text-base">Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            {error ? (
                <p>
                    <i>{error.message ?? 'Unknown error'}</i>
                </p>
            ) : null}
        </div>
    );
}
