import { useQuery } from '@/lib/hooks/useQuery';
import type { ListCategoryResponse } from 'lib/models/category';
import type { CnslCategory } from 'lib/prisma/types';

export const useCategories = () => {
    return useQuery<ListCategoryResponse>({
        url: `/api/v1/categories`,
    });
};

export const useFlatCategories = () => {
    return useQuery<{ categories: CnslCategory[] }>({
        url: `/api/v1/categories/flat`,
    });
};
