import { useQuery } from '@/lib/hooks/useQuery';
import qs from 'qs';

export const useGoogleGetOAuthUrl = () => {
    return useQuery<{ url: string }>({
        url: '/api/v1/integrations/google/url',
    });
};

export const useGoogleSignupUrl = () => {
    return useQuery<{ url: string }>({
        url: '/auth/google/url',
    });
};

export const useJiraGetOAuthUrl = (redirectPath?: string) => {
    const params = redirectPath ? { redirectPath } : {};
    return useQuery<{ url: string }>({
        url: `/api/v1/integrations/jira/url?${qs.stringify(params)}`,
    });
};

export const useOktaGetOAuthUrl = () => {
    return useQuery<{ url: string }>({
        url: '/api/v1/integrations/okta/url',
    });
};
