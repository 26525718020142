import { useQuery } from '@/lib/hooks/useQuery';
import type { PaginatedListResponse } from 'lib/models/pagination';
import type { CnslGroup } from 'lib/prisma/types';
import qs from 'qs';

export const useGroups = (groupIds?: string[]) => {
    let enabled = true;
    let queryKey: string[] | undefined;
    if (groupIds) {
        enabled = groupIds.length > 0;
        queryKey = ['groupsByIds', ...groupIds];
    }
    return useQuery<PaginatedListResponse<CnslGroup>>({
        url: `/api/v1/groups?${qs.stringify({ limit: 1000, ids: groupIds })}`,
        queryKey,
        enabled,
    });
};
