import { type FlagKey, defaultFlags } from 'lib/flags';
import { PostHogFeature, type PostHogFeatureProps } from 'posthog-js/react';
import type React from 'react';

interface FlaggedFeatureProps extends PostHogFeatureProps {
    flag: FlagKey;
    match?: string | boolean;
    fallback?: React.ReactNode;
}

const FlaggedFeature: React.FC<FlaggedFeatureProps> = ({ flag, match = true, children, fallback, ...props }) => {
    // NOTE: The idea here is that in development mode only, we should render as if the flag is true if
    // the local flag is set to the match value. This is useful for quickly testing a feature without
    // having to change the flag in PostHog.
    const flagHasLocalMatch =
        import.meta.env.MODE === 'development' && flag in defaultFlags && defaultFlags[flag] === match;

    return (
        <PostHogFeature flag={flag} match={match} fallback={flagHasLocalMatch ? children : fallback} {...props}>
            {children}
        </PostHogFeature>
    );
};

export default FlaggedFeature;
