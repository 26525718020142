import { EntryScreen } from '@/components/EntryScreen';
import FlaggedFeature from '@/components/FlaggedFeature';
import { Loader } from '@/components/loader';
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button/button';
import { CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useInitializeOkta } from '@/hooks/mutations/useInitialize';
import { Input } from '@ui/input';
import { ProviderAssets } from 'lib/3p';
import { FlagKey } from 'lib/flags/keys';
import { InitializeOktaPayloadSchema } from 'lib/models/intitialize';
import { ArrowRight, Check } from 'lucide-react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { safeParse } from 'valibot';

const OktaSuccess = () => {
    const config = ProviderAssets.okta;

    const circles = ['size-[72px]', 'size-[160px]', 'size-[240px]', 'size-[320px]'];
    return (
        <div className="flex flex-col items-center justify-center gap-md w-full mt-lg h-40  relative overflow-hidden">
            {circles.map(size => (
                <div key={size} className={`absolute ${size} border-[0.5px] rounded-full border-grey`} />
            ))}
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-white" />
            <div className="absolute size-[24px] right-[145px] top-[45px] bg-bg-green-secondary rounded-full text-white flex items-center justify-center">
                <Check />
            </div>
            <div className="z-10 flex items-center justify-center">
                <Avatar size="lg">
                    <AvatarImage src={config.logo} alt={config.name} rounded="square" />
                </Avatar>
            </div>
            <div className="z-10 text-center absolute top-[110px]">
                <h2 className="text-xl font-semibold">Okta Connected</h2>
                <p className="text-sm text-body-subtle-hover">You can now login</p>
            </div>
        </div>
    );
};

const ConfigureSettings = ({ token }: { token: string }) => {
    const { mutate: initializeOkta } = useInitializeOkta();
    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [domain, setDomain] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const parsed = safeParse(InitializeOktaPayloadSchema, {
        clientId,
        clientSecret,
        domain,
        token,
    });
    const valid = parsed.success;

    const formConfig = [
        {
            label: 'Domain',
            id: 'domain',
            value: domain,
            onChange: setDomain,
            placeholder: 'https://acme.okta.com',
        },
        {
            label: 'Client Id',
            id: 'client-id',
            value: clientId,
            onChange: setClientId,
            placeholder: 'your client id...',
        },
        {
            label: 'Client Secret',
            id: 'client-secret',
            value: clientSecret,
            onChange: setClientSecret,
            placeholder: 'your client secret...',
        },
    ];
    const handleSubmit = async () => {
        if (!valid) {
            return;
        }
        setLoading(true);
        initializeOkta(parsed.output, {
            onSettled: () => {
                setLoading(false);
            },
            onSuccess: () => {
                setSuccess(true);
            },
        });
    };
    return (
        <>
            {!success && (
                <CardHeader className="px-xl">
                    <CardTitle className="text-center">Connect Okta</CardTitle>
                    <CardDescription className="text-center text-sm text-body-subtle-hover">
                        Enter Okta app credentials
                    </CardDescription>
                </CardHeader>
            )}
            <CardContent className={success ? 'p-0 -mt-[40px]' : 'px-xl'}>
                {success ? (
                    <OktaSuccess />
                ) : (
                    <div className="flex flex-col gap-md w-full mt-lg">
                        {formConfig.map(field => (
                            <label htmlFor={field.id} key={field.id}>
                                {field.label}
                                <Input
                                    id={field.id}
                                    className="w-full"
                                    placeholder={field.placeholder}
                                    value={field.value}
                                    onChange={e => field.onChange(e.target.value)}
                                />
                            </label>
                        ))}
                    </div>
                )}
            </CardContent>

            <CardFooter className="p-lg">
                {success ? (
                    <Button className="w-full" asChild>
                        <Link to="/login">
                            Login
                            <ArrowRight />
                        </Link>
                    </Button>
                ) : (
                    <Button className="w-full" onClick={handleSubmit} disabled={!valid || loading}>
                        {loading ? 'Verifying' : 'Save'}
                        {loading && <Loader className="w-4 h-4 ml-md" />}
                    </Button>
                )}
            </CardFooter>
        </>
    );
};

const InitializeOrg = () => {
    const { token } = useParams();
    if (!token) {
        return null;
    }

    return (
        <FlaggedFeature flag={FlagKey.OktaInitialize}>
            <EntryScreen cardClassName="px-0 pb-0">
                <ConfigureSettings token={token} />
            </EntryScreen>
        </FlaggedFeature>
    );
};

export default InitializeOrg;
