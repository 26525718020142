import { cn } from '@/lib/styling';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip';

interface Props<T> {
    readonly items: T[];
    renderItem: (item: T, index: number) => React.ReactNode;
    getTooltipContent: (item: T) => string;
}

export const Stacked = <T,>({ items, renderItem, getTooltipContent }: Props<T>) => {
    const [width, setWidth] = useState<number>(0);
    const ref = useRef<HTMLDivElement[]>([]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <?>
    useEffect(() => {
        if (ref.current[0]) {
            setWidth(ref.current[0].clientWidth);
        }
    }, [items]);

    const displayedItems = items.slice(0, 3);
    const remainingCount = items.length - 3;

    return (
        <TooltipProvider>
            <div className="flex items-center">
                {displayedItems.map((item, index) => (
                    <Tooltip key={index}>
                        <TooltipTrigger asChild>
                            <div
                                className="flex items-center"
                                ref={el => {
                                    ref.current[index] = el as HTMLDivElement;
                                }}
                                style={{ marginLeft: index > 0 ? -width / 3 : 0 }}
                            >
                                {renderItem(item, index)}
                            </div>
                        </TooltipTrigger>
                        <TooltipContent>
                            {typeof getTooltipContent === 'function'
                                ? getTooltipContent(item)
                                : 'Tooltip content unavailable'}
                        </TooltipContent>
                    </Tooltip>
                ))}
                {remainingCount > 0 && (
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div
                                className={cn(
                                    'flex items-center justify-center rounded-full bg-bg-grey-secondary text-body-grey-secondary ring-1 ring-white shrink-0 grow-0 z-10 font-medium',
                                )}
                                style={{ marginLeft: -width / 3, width: width, height: width, fontSize: width / 2 }}
                            >
                                +{remainingCount}
                            </div>
                        </TooltipTrigger>
                        <TooltipContent>
                            {remainingCount} more {remainingCount === 1 ? 'item' : 'items'}
                        </TooltipContent>
                    </Tooltip>
                )}
            </div>
        </TooltipProvider>
    );
};
