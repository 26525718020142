import { cva } from 'class-variance-authority';

const buttonVariants = cva(
    'disabled:text-body-subtle disabled:bg-bg-grey-primary inline-flex items-center justify-center whitespace-nowrap rounded-[6px] transition-colors disabled:pointer-events-none disabled:shadow-none font-medium',
    {
        variants: {
            variant: {
                default: 'bg-bg border-grey hover:bg-bg-elevated border-[0.5px] shadow',
                grey: '',
                red: '',
                yellow: '',
                green: '',
                blue: '',
            },
            mode: {
                light: '',
                dark: '',
                borderless: '',
            },
            size: {
                default: 'px-md h-8 min-w-8 gap-md',
                sm: 'h-6 min-w-6 rounded-[5px] px-[5px] text-xs gap-sm',
            },
        },
        compoundVariants: [
            {
                variant: 'grey',
                mode: 'light',
                className: 'bg-bg-grey-primary hover:bg-bg-grey-primary-hover border-grey border-[0.5px]',
            },
            {
                variant: 'red',
                mode: 'light',
                className:
                    'bg-bg-red-primary hover:bg-bg-red-primary-hover text-body-red-primary hover:text-body-red-primary-hover border-red border-[0.5px]',
            },
            {
                variant: 'yellow',
                mode: 'light',
                className:
                    'bg-bg-yellow-primary hover:bg-bg-yellow-primary-hover text-body-yellow-primary hover:text-body-yellow-primary-hover border-yellow border-[0.5px]',
            },
            {
                variant: 'green',
                mode: 'light',
                className:
                    'bg-bg-green-primary hover:bg-bg-green-primary-hover text-body-green-primary hover:text-body-green-primary-hover border-green border-[0.5px]',
            },
            {
                variant: 'blue',
                mode: 'light',
                className:
                    'bg-bg-blue-primary hover:bg-bg-blue-primary-hover text-body-blue-primary hover:text-body-blue-primary-hover border-blue border-[0.5px]',
            },
            {
                variant: 'grey',
                mode: 'dark',
                className:
                    'bg-bg-grey-secondary hover:bg-bg-grey-secondary-hover text-body-grey-secondary hover:text-body-grey-secondary-hover',
            },
            {
                variant: 'red',
                mode: 'dark',
                className:
                    'bg-bg-red-secondary hover:bg-bg-red-secondary-hover text-body-red-secondary hover:text-body-red-secondary-hover',
            },
            {
                variant: 'yellow',
                mode: 'dark',
                className:
                    'bg-bg-yellow-secondary hover:bg-bg-yellow-secondary-hover text-body-yellow-secondary hover:text-body-yellow-secondary-hover',
            },
            {
                variant: 'green',
                mode: 'dark',
                className:
                    'bg-bg-green-secondary hover:bg-bg-green-secondary-hover text-body-green-secondary hover:text-body-green-secondary-hover',
            },
            {
                variant: 'blue',
                mode: 'dark',
                className:
                    'bg-bg-blue-secondary hover:bg-bg-blue-secondary-hover text-body-blue-secondary hover:text-body-blue-secondary-hover',
            },
            {
                variant: 'default',
                mode: 'borderless',
                className: 'bg-transparent hover:bg-bg-grey-primary text-body-grey-primary border-none shadow-none',
            },
            {
                variant: 'grey',
                mode: 'borderless',
                className: 'bg-transparent hover:bg-bg-grey-primary text-body-grey-primary border-none shadow-none',
            },
            {
                variant: 'red',
                mode: 'borderless',
                className: 'bg-transparent hover:bg-bg-red-primary text-body-red-primary border-none shadow-none',
            },
            {
                variant: 'yellow',
                mode: 'borderless',
                className: 'bg-transparent hover:bg-bg-yellow-primary text-body-yellow-primary border-none shadow-none',
            },
            {
                variant: 'green',
                mode: 'borderless',
                className: 'bg-transparent hover:bg-bg-green-primary text-body-green-primary border-none shadow-none',
            },
            {
                variant: 'blue',
                mode: 'borderless',
                className: 'bg-transparent hover:bg-bg-blue-primary text-body-blue-primary border-none shadow-none',
            },
        ],
        defaultVariants: {
            variant: 'default',
            mode: 'light',
            size: 'default',
        },
    },
);

export { buttonVariants };
