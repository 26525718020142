import { Button } from '@/components/ui/button/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useHelpDeskConfigUpsert } from '@/hooks/mutations/useHelpdesk';
import { useHelpDeskConfigs, useHelpDeskProvidersStatus } from '@/hooks/queries/useHelpdesk';
import { useHelpDeskStore } from '@/stores/useHelpDesk';
import { ticketTypeSettings } from 'lib/models/help_desk';
import { CnslTicketType, type CnslTicketTypeType } from 'lib/prisma/enums';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { HelpDeskFieldMapping } from './HelpDeskFieldMapping';
import { HelpDeskPicker } from './HelpDeskPicker';

const rootPath = '/settings/requests/help-desk-configuration';

export const HelpDeskRoot = () => {
    const { data, isFetched } = useHelpDeskConfigs();
    const { subsetting } = useParams();
    const providers = useHelpDeskProvidersStatus();
    const [tab, setTab] = useState(subsetting ?? 'it-support');
    const upsert = useHelpDeskConfigUpsert();

    const enumMap: Record<string, CnslTicketTypeType> = {
        'it-support': CnslTicketType.IT_SUPPORT,
        'app-access': CnslTicketType.APP_ACCESS,
    };
    const configs = data?.configs ?? [];

    const cnslTicketType = enumMap[tab];
    const settings = ticketTypeSettings[cnslTicketType];
    const store = useHelpDeskStore();

    const config = configs.find(c => c.cnslTicketType === cnslTicketType);

    useEffect(() => {
        store.reset();
        store.setCnslTicketType(cnslTicketType);
    }, [cnslTicketType, store.reset, store.setCnslTicketType]);

    useEffect(() => {
        if (config) {
            store.reset();
            store.setCurrent(config);
        }
    }, [config, store.reset, store.setCurrent]);

    useEffect(() => {
        if (isFetched && store.isDirty()) {
            const payload = store.payload();
            if (payload) {
                upsert.mutate(payload);
                store.setCurrent({
                    ...payload,
                    ticketSubType: payload.ticketSubType ?? null,
                });
            }
        }
    }, [isFetched, upsert, store.isDirty, store.payload, store.setCurrent]);

    const anyEnabled = providers.some(p => p.enabled);

    return (
        <>
            {anyEnabled ? (
                <Tabs defaultValue="it-support" value={tab} onValueChange={setTab} key={tab}>
                    <TabsList>
                        <Link to={`${rootPath}/it-support`}>
                            <TabsTrigger value="it-support" className="gap-sm">
                                IT Support Requests
                            </TabsTrigger>
                        </Link>

                        <Link to={`${rootPath}/app-access`}>
                            <TabsTrigger value="app-access" className="gap-sm">
                                App Access Requests
                            </TabsTrigger>
                        </Link>
                    </TabsList>
                    <TabsContent value={tab} className="mt-8">
                        <div className="flex flex-col gap-lg border-grey rounded-md border-[0.5px] p-lg">
                            <HelpDeskPicker />
                        </div>
                        {store.provider && store.namespace && (
                            <div className="mt-xl">
                                <HelpDeskFieldMapping
                                    settings={settings}
                                    provider={store.provider}
                                    namespace={store.namespace}
                                    cnslTicketType={cnslTicketType}
                                    ticketSubType={store.ticketSubType}
                                />
                            </div>
                        )}
                    </TabsContent>
                </Tabs>
            ) : (
                <div className="flex flex-col gap-md">
                    <p className="text-sm">Connect a helpdesk to get started</p>
                    <Link to="/settings/organization/integrations">
                        <Button>Integrations</Button>
                    </Link>
                </div>
            )}
        </>
    );
};
