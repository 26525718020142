import { useQuery } from '@/lib/hooks/useQuery';
import type { OrgOnboardingType } from 'lib/models/org';
import { ONBOARDING_QUERY_KEY } from '../useSubscribeOnboardingFlow';

export const useOrgOnboarding = () => {
    return useQuery<{ onboarding: OrgOnboardingType }>({
        url: ONBOARDING_QUERY_KEY,
        headers: {
            // disable the browser caching for this query
            'Cache-Control': 'no-store',
        },
        // refetch every 30 seconds to try to keep the state in sync with backend updates
        refetchInterval: 1000 * 30,
        refetchOnWindowFocus: 'always',
        refetchOnMount: 'always',
        refetchOnReconnect: 'always',
        refetchIntervalInBackground: true,
    });
};

export const useWimpyOrgOnboarding = () => {
    return useQuery<{ onboarding: OrgOnboardingType }>({
        url: ONBOARDING_QUERY_KEY,
        queryKey: [ONBOARDING_QUERY_KEY, 'wimpy'],
    });
};
