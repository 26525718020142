import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';

export const useDeleteInvite = (id: string) => {
    const queryClient = useQueryClient();
    return useMutation<{ ok: boolean }, DefaultError, { id: string }>({
        url: `/api/v1/members/invite/${id}`,
        method: 'DELETE',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: [`/api/v1/members/invited`], exact: true });
        },
        successToast: {
            message: 'Invite deleted',
        },
    });
};
