import type { ApiError } from 'lib/models/error';

import { ROOT } from '@/routes/paths';

interface Config<V> extends Omit<RequestInit, 'body'> {
    body?: V;
}

function initFetch(base?: string) {
    const baseURL = !base ? window.location.origin.replace('app', 'api') : base;

    // Response type and body type are generic
    return async <T = Response, V = unknown>(resource: string, config: Config<V> = {}): Promise<T> => {
        const url = resource.startsWith(ROOT) ? `${baseURL}${resource}` : resource;
        const { body, headers, ...rest } = config;

        const newConfig: RequestInit = {
            ...rest,
            mode: 'cors',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        };

        if (body) {
            newConfig.body = JSON.stringify(body);
        }

        // biome-ignore lint/style/noRestrictedGlobals: this is the base fetch we want to use instead of the global
        const r = await fetch(url, newConfig);

        if (r.redirected) {
            return {
                status: r.status,
                url: r.url,
            } as unknown as T;
        }

        let json: T = {} as T;
        try {
            json = await r.json();
        } catch (_error) {
            if (r.ok) {
                json = null as T;
            }
        }

        if (!r.ok) {
            // assuming that the BE returns the correct error object
            const e = json as ApiError;
            if (e.error.code === 500) {
                throw new Error(JSON.stringify(e.error.messages));
            }
        }

        return json as T;
    };
}

const baseFetch = initFetch();

export { baseFetch };
