import { Goal } from 'lucide-react';
import { useState } from 'react';

import { Button } from '@/components/ui/button/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter } from '@/components/ui/dialog';
import { useOnboardingStatusUpdate } from '@/hooks/mutations/useOnboardingStatus';
import { OrgOnboardingStatus } from 'lib/prisma/enums';

export const OnboardingCompletedDialog = () => {
    const [open, setOpen] = useState(true);
    const onboardingStatus = useOnboardingStatusUpdate();

    const handleClose = () => {
        onboardingStatus.mutate({ status: OrgOnboardingStatus.HIDDEN });
        setOpen(false);
    };

    return (
        <Dialog open={open} onOpenChange={handleClose}>
            <DialogContent>
                <DialogDescription className="px-2xl pt-3xl flex flex-col items-center gap-lg">
                    <div className="w-24 h-24 flex items-center justify-center rounded-full bg-bg-blue-secondary">
                        <Goal className="w-16 h-16 text-white" />
                    </div>
                    <div className="text-body-subtle">
                        Great job! You have configured Console to work end-to-end with your tools. Any new Playbooks and
                        Policies that you build will be useable through Slack by typing /help
                    </div>
                </DialogDescription>
                <DialogFooter className="flex justify-center">
                    <Button mode="borderless" onClick={handleClose}>
                        Close
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
