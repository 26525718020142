import { useMutation } from '@lib/hooks/useMutation';
import type { DefaultError } from '@tanstack/react-query';
import type { Org } from 'lib/prisma/types';

export const useOrgNamePut = () => {
    return useMutation<Org, DefaultError, { name: string }>({
        url: `/api/v1/org/name`,
        method: 'PUT',
        successToast: {
            message: 'Organization name updated',
        },
        errorToast: {
            message: 'Problem updating organization name',
        },
    });
};
