import { cn } from '@/lib/styling';

export const ProgressCircle = ({ progress, className }: { progress: number; className?: string }) => {
    const p = 100 - progress * 100;

    return (
        <svg className={cn('size-6', className)} viewBox="0 0 40 40">
            <title>Progress</title>
            <path
                className="text-steel-600"
                d="M20 3
                   a 17 17 0 0 1 0 34
                   a 17 17 0 0 1 0 -34"
                fill="none"
                stroke="currentColor"
                strokeWidth="6"
            />
            <path
                className="text-body-blue-primary"
                d="M20 3
                   a 17 17 0 0 1 0 34
                   a 17 17 0 0 1 0 -34"
                fill="none"
                stroke="currentColor"
                strokeWidth="6"
                strokeDasharray="100"
                strokeDashoffset={p}
            />
        </svg>
    );
};
