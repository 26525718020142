import { type VariantProps, cva } from 'class-variance-authority';

export const avatarVariants = cva('inline-flex shrink-0 items-center', {
    variants: {
        size: {
            default: 'size-6 text-[10px]', // 24 × 24 : 10px
            sm: 'size-4 text-[7px]', // 16 × 16 : 7px
            lg: 'size-8 text-sm', // 32 × 32 : 14px
            xl: 'size-10 text-base', // 40 × 40 : 16px
        },
    },
    defaultVariants: {
        size: 'default',
    },
});

export const avatarImgVariants = cva('size-full object-contain', {
    variants: {
        rounded: {
            default: 'rounded-full',
            square: '',
        },
        bgColor: {
            default: '',
            tomato: 'bg-[#E54D2E] text-body-white',
            red: 'bg-[#E5484D] text-body-white',
            crimson: 'bg-[#E93D82] text-body-white',
            pink: 'bg-[#D6409F] text-body-white',
            plumb: 'bg-[#AB4ABA] text-body-white',
            purple: 'bg-[#8E4EC6] text-body-white',
            violet: 'bg-[#6E56CF] text-body-white',
            indigo: 'bg-[#3E63DD] text-body-white',
            blue: 'bg-[#0091FF] text-body-white',
            cyan: 'bg-[#05A2C2] text-body-white',
            teal: 'bg-[#12A594] text-body-white',
            green: 'bg-[#30A46C] text-body-white',
            grass: 'bg-[#46A758] text-body-white',
            orange: 'bg-[#F76808] text-body-white',
            brown: 'bg-[#AD7F58] text-body-white',
            sky: 'bg-[#2EBDE5] text-body-white',
            mint: 'bg-[#40C4AA] text-body-white',
            lime: 'bg-[#94BA2C] text-body-white',
            yellow: 'bg-[#EBBC00] text-body-white',
            amber: 'bg-[#EE9D2B] text-body-white',
        },
    },
    defaultVariants: {
        rounded: 'default',
        bgColor: 'default',
    },
});

export type AvatarVariantSize = VariantProps<typeof avatarVariants>['size'];
export type AvatarImgVariantRounded = VariantProps<typeof avatarImgVariants>['rounded'];
