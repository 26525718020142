import React from 'react';

import { Separator } from '@/components/ui/separator/separator';
import type { MenuListProps } from 'react-select';
import type { GroupBase } from 'react-select';
import { components } from 'react-select';

export const MenuListSelectedTop = <
    Option,
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: MenuListProps<Option, IsMulti, Group>,
) => {
    const selectedIds = (props.getValue() as readonly object[]).map(o => ('value' in o ? o.value : undefined));

    const selectedChildren: React.ReactNode[] = [];
    const children: React.ReactNode[] = [];

    React.Children.toArray(props.children).forEach(c => {
        if (typeof c === 'object' && 'props' in c && selectedIds.includes(c.props.value)) selectedChildren.push(c);
        else children.push(c);
    });

    return (
        <components.MenuList {...props}>
            {selectedChildren.length > 0 && (
                <>
                    {selectedChildren}
                    <Separator className="my-sm" />
                </>
            )}
            {children}
        </components.MenuList>
    );
};
