import kebabCase from 'lodash-es/kebabCase';

import type { Setting } from './types/Settings';

export const findGroup = (settings: Setting[], group: string | undefined) =>
    settings.find(g => kebabCase(g.label) === group);

export const findSettingItem = (settings: Setting[] | undefined, item: string | undefined) =>
    settings?.find(s => (s.index && !item) || kebabCase(s.pathname ?? s.label) === item);

export const formatPathname = (base: string, group: string, item?: string) =>
    `${base}/${kebabCase(group)}${item ? `/${kebabCase(item)}` : ''}`;
