import * as SwitchPrimitives from '@radix-ui/react-switch';
import { rootVariants, thumbVariants } from '@ui/switch';
import type { VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/styling';

export interface SwitchProps
    extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
        VariantProps<typeof rootVariants> {}

const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(
    ({ className, size, ...props }, ref) => (
        <SwitchPrimitives.Root className={cn(rootVariants({ size }), className)} {...props} ref={ref}>
            <SwitchPrimitives.Thumb className={cn(thumbVariants({ size }))} />
        </SwitchPrimitives.Root>
    ),
);
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
