import { cn } from '@/lib/styling';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import type { VariantProps } from 'class-variance-authority';
import { X } from 'lucide-react';
import * as React from 'react';

import { dialogVariants } from './dialogVariants';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Overlay
        ref={ref}
        className={cn(
            'data-[state=open]:animate-dialogOverlayShow data-[state=closed]:animate-dialogOverlayHide fixed inset-0 z-50 bg-black/20 backdrop-blur-sm',
            className,
        )}
        {...props}
    />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & VariantProps<typeof dialogVariants>
>(({ className, children, size, ...props }, ref) => (
    <DialogPortal>
        {/* The default DialogPrimitive.Content wasn't originally wrapped by DialogOverlay but it was causing the react-select menus to not scroll while the dialog was open. Wrapping the content in the DialogOverlay fixes this. */}
        <DialogOverlay>
            <DialogPrimitive.Content
                ref={ref}
                className={cn(
                    dialogVariants({ size }),
                    'bg-bg data-[state=open]:animate-dialogContentShow data-[state=closed]:animate-dialogContentHide fixed left-[50%] top-[50%] z-50 w-full mx-lg translate-x-[-50%] translate-y-[-50%] shadow-lg rounded-lg max-h-[80vh] flex flex-col',
                    className,
                )}
                {...props}
            >
                {children}
                <DialogPrimitive.Close className="p-sm hover:bg-bg-grey-primary absolute right-4 top-4 rounded-full disabled:pointer-events-none">
                    <X />
                    <span className="sr-only">Close</span>
                </DialogPrimitive.Close>
            </DialogPrimitive.Content>
        </DialogOverlay>
    </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

function DialogHeader({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
    return <div className={cn('flex p-lg flex-col gap-sm text-center sm:text-left', className)} {...props} />;
}
DialogHeader.displayName = 'DialogHeader';

function DialogFooter({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            className={cn(
                'flex sm:flex-row flex-col-reverse justify-between gap-md p-lg border-grey border-t-[0.5px]',
                className,
            )}
            {...props}
        />
    );
}
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Title ref={ref} className={cn('text-base flex items-center gap-md', className)} {...props} />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Description ref={ref} className={cn('text-body-subtle-hover', className)} {...props} />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    DialogPortal,
    DialogTitle,
    DialogTrigger,
};
