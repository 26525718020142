import { cva } from 'class-variance-authority';

const separatorVariants = cva('shrink-0', {
    variants: {
        variant: {
            default: 'border-grey border-t-[0.5px]',
            subtle: 'border-subtle border-t-[0.5px]',
        },
        orientation: {
            horizontal: 'h-[0.5px] w-full',
            vertical: 'h-full w-[0.5px]',
        },
    },
    defaultVariants: {
        variant: 'default',
        orientation: 'horizontal',
    },
});

export { separatorVariants };
