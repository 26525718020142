import { useMutation } from '@lib/hooks/useMutation';
import type { DefaultError } from '@tanstack/react-query';
import type { InitializeOktaPayload } from 'lib/models/intitialize';

export const useInitializeOkta = () => {
    return useMutation<InitializeOktaPayload, DefaultError>({
        url: `/api/initialize/okta`,
        method: 'POST',
        successToast: {
            message: 'Okta connected',
        },
    });
};
