import { Check } from 'lucide-react';
import type { GroupBase, OptionProps, SingleValueProps, ValueContainerProps } from 'react-select';
import { components as SelectPrimitiveComponents } from 'react-select';

import { ResLink } from '@/components/ResLink';
import { Stacked } from '@/components/Stacked';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import type { CnslUser } from 'lib/prisma/types';

export type CnslUserOption = { label: string; avatar: string | null; value: CnslUser; email?: string };
export type CnslGroupOption = { label: string; logo?: string; value: string };

export const UserResLinkValueContainer = <
    Option extends { label: string; avatar: string | null },
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>,
>({
    children,
    ...props
}: ValueContainerProps<Option, IsMulti, Group>) => {
    const isMultiple = props.getValue().length > 0;
    const isOne = props.getValue().length === 1;

    return (
        <SelectPrimitiveComponents.ValueContainer {...props}>
            {isMultiple && (
                <div className="gap-md flex items-center">
                    {!isOne ? (
                        <>
                            <Stacked
                                items={props.getValue() as Option[]}
                                renderItem={(item, index) => (
                                    <ResLink avatarOnly label={item.label} key={index} src={item.avatar} size="sm" />
                                )}
                                getTooltipContent={item => item.label}
                            />{' '}
                            <Tooltip>
                                <TooltipTrigger>
                                    <div>{`${props.getValue().length} selected`}</div>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {props
                                        .getValue()
                                        .map(item => item.label)
                                        .join(', ')}
                                </TooltipContent>
                            </Tooltip>
                        </>
                    ) : (
                        <ResLink label={props.getValue()[0].label} src={props.getValue()[0].avatar} size="sm" />
                    )}
                </div>
            )}
            {children}
        </SelectPrimitiveComponents.ValueContainer>
    );
};

export const UserResLinkOption = <
    Option extends CnslUserOption,
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: OptionProps<Option, IsMulti, Group>,
) => {
    const { children, ...restProps } = props;
    const data = restProps.data;

    return (
        <SelectPrimitiveComponents.Option {...restProps}>
            <div className="items center flex justify-between">
                <ResLink label={data.label} src={data.avatar} size="sm" description={data.email} />
                {restProps.isSelected && <Check />}
            </div>
        </SelectPrimitiveComponents.Option>
    );
};

export const ReslinkValue = <T extends { label: string; avatar: string | null }>(props: SingleValueProps<T>) => {
    return (
        <SelectPrimitiveComponents.SingleValue {...props}>
            <ResLink label={props.data.label} src={props.data.avatar} size="sm" />
        </SelectPrimitiveComponents.SingleValue>
    );
};

export const UserAndGroupResLinkValueContainer = <
    Option extends { label: string; avatar: string | null; type: 'user' | 'group' },
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>,
>({
    children,
    ...props
}: ValueContainerProps<Option, IsMulti, Group>) => {
    const isMultiple = props.getValue().length > 0;
    const isOne = props.getValue().length === 1;
    const groupCount = props.getValue().filter(item => item.type === 'group').length;
    const userCount = props.getValue().filter(item => item.type === 'user').length;

    const pluralize = (count: number) => (count > 1 ? 's' : '');
    const userStr = userCount ? `${userCount} user${pluralize(userCount)}` : '';
    const groupStr = groupCount ? `${groupCount} group${pluralize(groupCount)}` : '';
    const selectedLabel = [userStr, groupStr].filter(Boolean).join(' and ');

    return (
        <SelectPrimitiveComponents.ValueContainer {...props}>
            {isMultiple ? (
                <div className="gap-md flex items-center">
                    {!isOne ? (
                        <>
                            <Stacked
                                items={props.getValue() as Option[]}
                                renderItem={(item, index) => (
                                    <ResLink avatarOnly label={item.label} key={index} src={item.avatar} size="sm" />
                                )}
                                getTooltipContent={item => item.label}
                            />{' '}
                            <Tooltip>
                                <TooltipTrigger>
                                    <div>{selectedLabel}</div>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {props
                                        .getValue()
                                        .map(item => item.label)
                                        .join(', ')}
                                </TooltipContent>
                            </Tooltip>
                        </>
                    ) : (
                        <ResLink label={props.getValue()[0].label} src={props.getValue()[0].avatar} size="sm" />
                    )}
                </div>
            ) : (
                children
            )}
        </SelectPrimitiveComponents.ValueContainer>
    );
};

export const GroupOrUserResLinkOption = <
    Option extends { value: string; type: 'user' | 'group'; avatar: string | null; label: string },
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: OptionProps<Option, IsMulti, Group>,
) => {
    const { children, ...restProps } = props;
    const data = restProps.data;

    return (
        <SelectPrimitiveComponents.Option {...restProps}>
            <div className="items center flex justify-between">
                <ResLink label={data.label} src={data.avatar} size="sm" />
                {restProps.isSelected && <Check />}
            </div>
        </SelectPrimitiveComponents.Option>
    );
};

export const GroupResLinkOption = <
    Option extends CnslGroupOption,
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: OptionProps<Option, IsMulti, Group>,
) => {
    const { children, ...restProps } = props;
    const data = restProps.data;

    return (
        <SelectPrimitiveComponents.Option {...restProps}>
            <div className="items center flex justify-between">
                <ResLink label={data.label} src={data.logo} size="sm" />
                {restProps.isSelected && <Check />}
            </div>
        </SelectPrimitiveComponents.Option>
    );
};

export const GroupResLinkValueContainer = <
    Option extends CnslGroupOption,
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>,
>({
    children,
    ...props
}: ValueContainerProps<Option, IsMulti, Group>) => {
    const value = props.getValue()[0];
    return (
        <SelectPrimitiveComponents.ValueContainer {...props}>
            {value ? <ResLink label={value.label} src={value.logo} size="sm" /> : children}
        </SelectPrimitiveComponents.ValueContainer>
    );
};
