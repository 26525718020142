import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const getSentryEnvironment = () => {
    const stackName = import.meta.env.VITE_STACKNAME;
    switch (stackName) {
        case 'staging':
            return 'Staging';
        case 'prod1':
            return 'Production';
        default:
            return 'Local';
    }
};

Sentry.init({
    enabled: getSentryEnvironment() !== 'Local',
    dsn: 'https://d0586d2421d6045f362bf99fc32a6c49@o4507686496960512.ingest.us.sentry.io/4507687994523648',
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration({ useCompression: false }),
    ],
    tunnel: `/api/sentry`,
    environment: getSentryEnvironment(),
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.(staging\.)?console\.co/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
