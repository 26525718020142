import * as Sentry from '@sentry/react';
import { Outlet } from 'react-router-dom';

import { ErrorPage } from '../errors';

/**
 * Wraps the entire app in Sentry
 */
export const PageIndex = () => {
    return (
        <Sentry.ErrorBoundary fallback={({ error }) => <ErrorPage error={error as Error} />}>
            <Outlet />
        </Sentry.ErrorBoundary>
    );
};
