import { AnimatePresence, motion } from 'framer-motion';
import { Outlet, useLocation } from 'react-router-dom';

import { GodModeBanner } from '@/components/GodModeBanner';
import { Toaster } from '@/components/ui/sonner';
import { cn } from '@/lib/styling';
import { useApp } from '@/stores/useApp';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import Nav from './Nav';

const Home = () => {
    const { app, setApp } = useApp();
    const { pathname } = useLocation();
    const [outOfViewport, setOutOfViewport] = useState(false);
    const [_, cancel] = useDebounce(
        () => {
            if (outOfViewport) {
                setApp({ isNavbarHovered: false });
            }
        },
        500,
        [outOfViewport],
    );

    useEffect(() => {
        const handleMouseLeave = () => {
            setOutOfViewport(true);
        };
        const handleMouseEnter = () => {
            setOutOfViewport(false);
        };

        document.addEventListener('mouseleave', handleMouseLeave);
        document.addEventListener('mouseenter', handleMouseEnter);

        return () => {
            cancel();
            document.removeEventListener('mouseleave', handleMouseLeave);
            document.addEventListener('mouseenter', handleMouseEnter);
        };
    }, [cancel]);

    const showSidebar = !pathname.startsWith('/onboarding');

    return (
        <>
            <GodModeBanner />
            <div className="p-md gap-md flex h-lvh pl-0">
                {showSidebar ? (
                    <div
                        className={cn(
                            'shrink-0 transition-all duration-100 ease-out overflow-hidden',
                            app.isNavbarOpen ? 'w-[240px]' : 'w-0',
                        )}
                    >
                        <Nav />
                    </div>
                ) : (
                    <div />
                )}
                {!app.isNavbarOpen && (
                    <div
                        className="w-xl fixed inset-0 z-50 h-full"
                        onMouseEnter={() => {
                            cancel();
                            if (!app.isNavbarOpen) {
                                setApp({ isNavbarHovered: true });
                            }
                        }}
                        onClick={() => {
                            setApp({ isNavbarOpen: !app.isNavbarOpen });
                            setApp({ isNavbarHovered: false });
                        }}
                    />
                )}
                <div className="bg-bg flex size-full flex-col overflow-hidden rounded-md shadow">
                    <AnimatePresence>
                        {app.isNavbarHovered && (
                            <motion.div
                                className="m-md fixed inset-0 z-50 mt-14 flex w-[248px] flex-col overflow-hidden"
                                initial={{ x: '-100%' }}
                                animate={{ x: 0 }}
                                exit={{ x: '-100%' }}
                                transition={{ duration: 0.15 }}
                                onMouseEnter={() => {
                                    cancel();
                                    setApp({ isNavbarHovered: true });
                                }}
                            >
                                <div className="bg-bg-overlay shadow-lpane pr-md pb-md grow">
                                    <Nav />
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <AnimatePresence>
                        {app.isNavbarHovered && (
                            <motion.div
                                className="m-md bg-black-20 fixed inset-0 z-40 mt-14 rounded-b-md backdrop-blur-sm"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.15 }}
                                onMouseEnter={() => setApp({ isNavbarHovered: false })}
                            />
                        )}
                    </AnimatePresence>
                    <Outlet />
                    <Toaster visibleToasts={4} />
                </div>
            </div>
        </>
    );
};

export default Home;
