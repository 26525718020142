import { useEffect, useRef } from 'react';

const LoadMore = ({ shouldLoadMore, cb }: { shouldLoadMore: boolean; cb: () => Promise<void> }) => {
    const loadMoreRef = useRef(null);
    const stableCb = useRef(cb);

    useEffect(() => {
        stableCb.current = cb;
    }, [cb]);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && shouldLoadMore) {
                    stableCb.current().catch(console.error);
                }
            });
        });

        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => observer.disconnect();
    }, [shouldLoadMore]);

    return <div ref={loadMoreRef} />;
};

export default LoadMore;
