import { EntryScreen } from '@/components/EntryScreen';
import FlaggedFeature from '@/components/FlaggedFeature';
import { Button } from '@/components/ui/button/button';
import { CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useIntegrationSettingPost } from '@/hooks/mutations/integrations/useIntegrationSettingPost';
import { useOrgNamePut } from '@/hooks/mutations/orgs/useOrgName';
import { useGoogleSignupUrl, useJiraGetOAuthUrl } from '@/hooks/queries/useOAuth';
import { useSignupStatus } from '@/hooks/queries/useSignup';
import { cn } from '@/lib/styling';
import { useUser } from '@/stores/useUser';
import { valibotResolver } from '@hookform/resolvers/valibot';
import { Input } from '@ui/input';
import { getProviderAssets } from 'lib/3p';
import { FlagKey } from 'lib/flags/keys';
import { IntegrationId } from 'lib/integration';
import { integrationDefaults, settingLabels } from 'lib/integration_settings/default';
import { IntegrationSettingsSchema } from 'lib/models/integrations';
import { ArrowLeft, ArrowRight, Info } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import type * as v from 'valibot';

const StepProgress = ({
    stepIndex,
    stepCount,
    backTo,
    status,
}: { stepIndex: number; stepCount: number; backTo?: string; status?: 'ready' | 'processing' }) => {
    const percentComplete = (stepIndex / stepCount) * 100;

    const barRound = percentComplete === 100 ? 'rounded-full' : 'rounded-l-full';
    return (
        <div
            className={cn(
                'border-grey border-t-[0.5px] w-full py-lg px-xl flex items-center justify-center gap-x-lg',
                !backTo && 'pl-14',
                status && 'pr-14',
            )}
        >
            {backTo && (
                <Link to={backTo}>
                    <Button mode="borderless">
                        <ArrowLeft />
                    </Button>
                </Link>
            )}
            {!status && (
                <div className="h-2 bg-bg-elevated rounded-full w-full">
                    <div
                        className={cn('h-2 bg-bg-blue-secondary', barRound)}
                        style={{ width: `${percentComplete}%` }}
                    />
                </div>
            )}
            {!status && (
                <div className="font-medium text-center w-6 shrink-0">
                    {stepIndex}/{stepCount}
                </div>
            )}

            {status === 'processing' && (
                <p className="text-body-grey-primary-hover">ROI Report generating, check back in ~15 minutes...</p>
            )}
            {status === 'ready' && (
                <Link to="/roi">
                    <Button variant="blue" mode="dark">
                        See your ROI report
                    </Button>
                </Link>
            )}
        </div>
    );
};

const ConnectGoogle = () => {
    const { data } = useGoogleSignupUrl();
    const googleIcon = getProviderAssets(IntegrationId.Google).logo;
    const [params] = useSearchParams();
    const error = params.get('error');

    if (error === 'not_work_email') {
        toast.error('Please use a work email to sign up', { duration: 15000 });
    }

    return (
        <>
            <CardHeader className="px-xl">
                <CardTitle className="text-center">Get started</CardTitle>
                <CardDescription className="text-center text-sm text-body-subtle-hover">
                    Resolve IT requests directly in Slack, before they hit your help desk
                </CardDescription>
            </CardHeader>
            <CardContent className="px-xl">
                <Button className="w-full mt-lg">
                    <a href={data?.url} rel="noreferrer" className="size-full flex gap-sm items-center justify-center">
                        <img src={googleIcon} alt="Google" className="size-[14px]" />
                        Sign up with Google
                    </a>
                </Button>
            </CardContent>

            <CardFooter />
        </>
    );
};

const ConfigureOrg = () => {
    const { user } = useUser();
    const [orgName, setOrgName] = useState(user?.orgMemberships?.[0]?.orgName);
    const putOrgName = useOrgNamePut();
    const navigate = useNavigate();
    const handleSubmit = () => {
        putOrgName.mutate({ name: orgName ?? '' });
        navigate('/onboarding/kb');
    };
    return (
        <>
            <CardHeader className="px-xl">
                <CardTitle className="text-center">Create your workspace</CardTitle>
                <CardDescription className="text-center text-sm text-body-subtle-hover">
                    Name your organization
                </CardDescription>
            </CardHeader>
            <CardContent className="px-xl pb-xl">
                <div className="flex flex-col gap-md w-full mt-lg">
                    <Input
                        className="w-full text-center capitalize"
                        placeholder="Acme, Inc."
                        value={orgName}
                        onChange={e => setOrgName(e.target.value)}
                    />
                    <Button className="w-full" onClick={handleSubmit}>
                        Continue <ArrowRight />
                    </Button>
                </div>
            </CardContent>

            <CardFooter className="pb-0">
                <StepProgress stepIndex={2} stepCount={3} backTo="/setup" />
            </CardFooter>
        </>
    );
};

const ConfigureTickets = () => {
    const [params] = useSearchParams();
    const providerParam = params.get('provider') as IntegrationId | null;
    const ticketSystems: IntegrationId[] = [
        IntegrationId.Freshservice,
        IntegrationId.Zendesk,
        IntegrationId.Jira,
    ].sort();
    const provider = providerParam && ticketSystems.includes(providerParam) ? providerParam : null;

    const navigate = useNavigate();
    const mutation = useIntegrationSettingPost();
    const { data } = useJiraGetOAuthUrl('/setup/sync');
    const defaultValues = useMemo(() => {
        return {
            provider,
            data: integrationDefaults[
                (provider as keyof typeof integrationDefaults) ??
                    (IntegrationId.Freshservice as keyof typeof integrationDefaults)
            ],
        };
    }, [provider]);
    const fields = Object.keys(defaultValues.data).filter(
        i => i !== 'sync' && i !== 'errors',
    ) as (keyof typeof settingLabels)[];

    const form = useForm<v.InferInput<typeof IntegrationSettingsSchema>>({
        resolver: valibotResolver(IntegrationSettingsSchema),
        // @ts-expect-error - fix
        defaultValues,
    });
    async function onSubmit(values: v.InferInput<typeof IntegrationSettingsSchema>) {
        if (provider) {
            values.provider = provider as IntegrationId.Freshservice | IntegrationId.Zendesk | IntegrationId.Jira;
        }
        const result = await mutation.mutateAsync(values);
        if ('status' in result && result.status) {
            navigate('/setup/sync');
        }
    }

    return (
        <>
            <CardHeader className="px-xl">
                {provider ? (
                    <>
                        <img src={getProviderAssets(provider).logo} alt={provider} className="size-2xl mx-auto" />
                        <CardTitle className="text-center capitalize">Connect {provider}</CardTitle>
                    </>
                ) : (
                    <>
                        <CardTitle className="text-center">Connect your ticketing tool</CardTitle>
                    </>
                )}
                <CardDescription className="text-center text-sm text-body-subtle-hover">
                    Once connected, we'll calculate your ROI
                </CardDescription>
            </CardHeader>
            <CardContent className="px-xl pb-xl">
                <div className="flex flex-col gap-md w-full mt-lg">
                    {!provider &&
                        ticketSystems.map(system =>
                            system === IntegrationId.Jira ? (
                                <Button key={system}>
                                    <a
                                        href={data?.url}
                                        rel="noreferrer"
                                        className="size-full flex items-center justify-center gap-md"
                                    >
                                        <img
                                            src={getProviderAssets(system).logo}
                                            alt={system}
                                            className="size-[14px]"
                                        />
                                        Connect Jira
                                    </a>
                                </Button>
                            ) : (
                                <Link to={`/setup/tickets?provider=${system}`} key={system}>
                                    <Button className="w-full capitalize">
                                        <img
                                            src={getProviderAssets(system).logo}
                                            alt={system}
                                            className="size-[14px]"
                                        />
                                        Connect {system}
                                    </Button>
                                </Link>
                            ),
                        )}
                    {provider && provider !== IntegrationId.Jira && (
                        <Form {...form}>
                            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-md">
                                {fields.map(f => (
                                    <FormField
                                        control={form.control}
                                        key={f}
                                        name={`data.${f}`}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>{settingLabels[f as keyof typeof settingLabels]}</FormLabel>
                                                <FormControl>
                                                    <Input {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                ))}
                                <Button
                                    className="w-full mt-xl"
                                    type="submit"
                                    disabled={mutation.isPending}
                                    onClick={() => onSubmit(form.getValues())}
                                >
                                    {mutation.isPending ? 'Submitting...' : 'Continue'} <ArrowRight />
                                </Button>
                            </form>
                        </Form>
                    )}
                    <div className="text-body-subtle-hover flex items-center gap-sm justify-center mt-lg -mb-3">
                        <span>What data will you collect?</span>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Info className="size-3" />
                            </TooltipTrigger>
                            <TooltipContent side="bottom">
                                <div className="max-w-80">
                                    We only pull 1 year of ticket data (titles, descriptions and requesters) to generate
                                    your ROI Report
                                    <div className="text-white-60">For more info, please email support@console.co</div>
                                </div>
                            </TooltipContent>
                        </Tooltip>
                    </div>
                </div>
            </CardContent>

            <CardFooter>
                <StepProgress stepIndex={3} stepCount={3} backTo={provider ? '/setup/tickets' : '/setup/org'} />
            </CardFooter>
        </>
    );
};

export const ProcessTickets = () => {
    const { data } = useSignupStatus();
    const tickets = [
        {
            id: 'LN-5137',
            title: 'Home office budget',
            color: 'bg-bg-chart-grass',
            description: 'I moved to a new appartment, can I tap into my home office budget a few months early?',
        },
        {
            id: 'LN-5203',
            title: `I need access to Cloudflare`,
            color: 'bg-bg-chart-gold',
            description: 'I need to investigate the DDOS incident from last night',
        },
        {
            id: 'LN-5212',
            title: `What's the wifi password?`,
            description: `We're hosting a client at the office and I want to make sure the wifi is accessible.`,
            color: 'bg-bg-chart-sky',
        },
    ];
    return (
        <>
            <CardHeader className="px-xl">
                <CardTitle className="text-center">Processing your tickets</CardTitle>
                <CardDescription className="text-center text-sm text-body-subtle-hover">
                    Hang tight, this will just take a moment
                </CardDescription>
            </CardHeader>
            <CardContent className="px-xl pb-xl">
                <div className="flex flex-col w-full mt-lg relative h-[170px]">
                    {tickets.map((t, idx) => {
                        const scale = 0.7 + idx * 0.1;
                        const translateY = idx * 48 + scrollY * (idx + 1) * 0.1;
                        return (
                            <div
                                key={t.id}
                                style={{
                                    transform: `scale(${scale}) translateY(${translateY}px)`,
                                    zIndex: 10 + idx,
                                }}
                            >
                                <div className="shadow-lgReverse h-[125px] absolute -top-1.5 left-0 w-full bg-gradient-to-b from-steel-300 to-white" />
                                <div className="flex flex-col gap-sm p-lg pt-xl absolute top-[-20px] left-0 w-full bg-gradient-to-b from-steel-300 to-white min-h-[125px] scallop">
                                    <div className="flex gap-sm items-center justify-between">
                                        <h3 className="text-base">{t.title}</h3>
                                        <div className="flex gap-md items-center">
                                            <div className={cn('rounded-[2px] size-2', t.color)} />
                                            <p className="text-sm text-body-subtle-hover">{t.id}</p>
                                        </div>
                                    </div>
                                    <p className="text-sm text-transparent bg-gradient-to-b from-steel-1100 to-white bg-clip-text">
                                        {t.description}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </CardContent>

            <CardFooter className="pb-0">
                <StepProgress stepIndex={874} stepCount={1206} status={data?.status} />
                {/* {data?.status === 'processing' ? <div>Processing...</div> : <Link to="/roi">See Report</Link>} */}
            </CardFooter>
        </>
    );
};

const SignUp = () => {
    let { step, token } = useParams();
    if (!step) {
        step = 'google';
    }

    const stepConfig: Record<string, { title?: string; description?: string; content: React.ReactNode }> = {
        google: {
            title: 'Sign up & calculate your ROI',
            description: 'Find out what percentage of your support requests can be automated with Console',
            content: <ConnectGoogle />,
        },
        org: {
            title: 'Create your workspace',
            description: 'Name your organization',
            content: <ConfigureOrg />,
        },
        tickets: {
            content: <ConfigureTickets />,
        },
        sync: {
            content: <ProcessTickets />,
        },
    };

    const config = stepConfig[step] ?? stepConfig.google;

    if (token && token === 'x7k9m4p2en') {
        return <EntryScreen cardClassName="px-0 pb-0">{config.content}</EntryScreen>;
    }

    return (
        <FlaggedFeature flag={FlagKey.ROISignup}>
            <EntryScreen cardClassName="px-0 pb-0">{config.content}</EntryScreen>
        </FlaggedFeature>
    );
};

export default SignUp;
