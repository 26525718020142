import { create } from 'zustand';

const LOCAL_STORAGE_KEY = 'applicationState';

export interface AppState {
    isNavbarOpen: boolean;
    isNavbarHovered: boolean;
}

interface Store {
    app: AppState;
    setApp: (app: Partial<AppState>) => void;
}

export const useApp = create<Store>(set => ({
    app: localStorage.getItem(LOCAL_STORAGE_KEY)
        ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) as string)
        : { isNavbarOpen: true, isNavbarHovered: false },
    setApp: (app: Partial<AppState>) => {
        set(state => {
            const newAppState = { ...state.app, ...app };
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newAppState));
            return { app: newAppState };
        });
    },
}));
