import { useMutation } from '@lib/hooks/useMutation';
import type { DefaultError } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import { useQuery } from '@/lib/hooks/useQuery';
import type { MergeIntegrationId } from 'lib/integration';

export const useMergeGetMergeToken = (provider: MergeIntegrationId) => {
    return useQuery<{ linkToken: string }>({
        url: `/api/v1/merge/hris/${provider}`,
    });
};

export const useMergeSendPublicToken = (provider: MergeIntegrationId) => {
    const queryClient = useQueryClient();
    return useMutation<{ ok: boolean }, DefaultError, { token: string }>({
        url: `/api/v1/merge/hris/${provider}/processPublicToken`,
        method: 'POST',
        successToast: {
            message: 'Integration created',
        },
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: ['/api/v1/integrations/status'], exact: true });
        },
    });
};
