import { type DefaultOrgchartSettings, DefaultOrgchartSettingsSchema } from 'lib/models/settings/orgchart';
import isEqual from 'lodash-es/isEqual';
import { safeParse } from 'valibot';
import { create } from 'zustand';

interface Store {
    currentSettings: DefaultOrgchartSettings | null;
    settings: DefaultOrgchartSettings | null;
    setSettings: (settings: Partial<DefaultOrgchartSettings>) => void;
    init: (settings: DefaultOrgchartSettings) => void;
    isDirty: () => boolean;
    isValid: () => boolean;
}

export const useOrgchartSettings = create<Store>((set, get) => ({
    currentSettings: null,
    settings: null,
    setSettings: (payload: Partial<DefaultOrgchartSettings>) => {
        set(state => {
            const parsed = safeParse(DefaultOrgchartSettingsSchema, payload);
            if (parsed.success) {
                return { settings: parsed.output };
            }
            return state;
        });
    },
    init: (settings: DefaultOrgchartSettings) => {
        set({ currentSettings: settings, settings: settings });
    },
    isDirty: () => {
        return !isEqual(get().currentSettings, get().settings);
    },
    isValid: () => {
        const settings = get().settings;
        if ((settings?.provider === 'OKTA' && settings?.field) || settings?.provider === 'HRIS') {
            return true;
        }
        return false;
    },
}));
