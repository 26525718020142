import { ONBOARDING_SUBSCRIBE_MUTATION_KEY } from '@/hooks/useSubscribeOnboardingFlow';
import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { IntegrationId } from 'lib/integration';

export const useIntegrationSettingDelete = ({ provider }: { provider: IntegrationId }) => {
    const queryClient = useQueryClient();

    return useMutation<{ success: boolean }, DefaultError, { provider: string }>({
        url: `/api/v1/integrations/settings/${provider}`,
        method: 'DELETE',
        mutationKey: [ONBOARDING_SUBSCRIBE_MUTATION_KEY],
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: ['/api/v1/integrations/status'] });
        },
    });
};
