import Group from '@pages/settings/components/Group';
import type { Setting } from '@pages/settings/types/Settings';

const SettingsNav = ({
    settings,
    activeRoute,
    basePath,
}: { settings: Setting[]; activeRoute?: string; basePath: string }) => {
    return (
        <div className="font-medium gap-sm p-md flex flex-col">
            {settings.map((setting, i) =>
                !setting.hide ? <Group key={i} activeRoute={activeRoute} basePath={basePath} {...setting} /> : null,
            )}
        </div>
    );
};

export default SettingsNav;
