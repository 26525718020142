import { useMutation } from '@lib/hooks/useMutation';
import { useQuery } from '@lib/hooks/useQuery';
import type { DefaultError } from '@tanstack/react-query';

export const useOrgSettingsUpsert = <T = { enabled: boolean }>(settingKey: string) => {
    return useMutation<T, DefaultError, unknown>({
        url: `/api/v1/settings/${settingKey}`,
        method: 'POST',
        successToast: {
            message: `Org setting updated`,
        },
    });
};

export const useOrgSettings = <T = { enabled: boolean }>(settingKey: string) => {
    return useQuery<T, DefaultError>({
        url: `/api/v1/settings/${settingKey}`,
    });
};
