import { Button } from '@/components/ui/button/button';
import { useInfiniteQuery } from '@tanstack/react-query';
import type {
    GetMembersResponse,
    SchemaSuspendMemberType,
    SchemaUnsuspendMemberType,
    SchemaUpdateMemberType,
} from 'lib/models/members';
import { CnslRole, type CnslRoleType } from 'lib/prisma/enums';
import { Ellipsis, Trash2, UserRoundPlus, UserRoundX } from 'lucide-react';
import qs from 'qs';
import { Link } from 'react-router-dom';

import LoadMore from '@/components/LoadMore';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar/avatar';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Select } from '@/components/ui/select';
import { useInvitedUsers } from '@/hooks/queries/useInvitedUsers';
import { useMemberReinstate, useMemberSuspend, useMemberUpdate } from '@/hooks/queries/useMembership';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import { initials } from '@/lib/initials';
import { cn } from '@/lib/styling';
import { useUser } from '@/stores/useUser';

import { useDeleteInvite } from '@/hooks/mutations/useDeleteInvite';
import type { InvitedUser } from 'lib/prisma/types';
import AddMember from './AddMember';
import { memberOptions } from './constants';

const MemberRow = ({
    member,
    cnslUser,
    changeRole,
    suspendMember,
    reinstateMember,
    disabled,
}: GetMembersResponse['items'][number] & {
    disabled: boolean;
    changeRole: (_: SchemaUpdateMemberType & { username: string }) => void;
    suspendMember: (_: SchemaSuspendMemberType) => void;
    reinstateMember: (_: SchemaUnsuspendMemberType) => void;
}) => {
    const name = cnslUser?.displayName || member.user.username;
    const email = cnslUser?.email || member.user.username;
    const i = initials(name || '');
    const hasCnslAccount = cnslUser?.email;

    const avatar = (
        <div className={cn('gap-md flex items-center !cursor-pointer', { 'opacity-50': disabled })}>
            <Avatar size="lg">
                <AvatarImage src={cnslUser?.avatar || undefined} alt={i} />
                <AvatarFallback>{i}</AvatarFallback>
            </Avatar>
            <div className="flex flex-col justify-center h-8">
                <div className="font-medium">{name}</div>
                <span className="text-body-subtle text-xs mt-[-2px]">{email}</span>
            </div>
        </div>
    );

    return (
        <div className="p-md border-subtle flex items-center justify-between border-b last:border-none">
            {hasCnslAccount ? <Link to={`/users/${email}`}>{avatar}</Link> : avatar}
            <div className="gap-md flex items-center">
                {!member.suspended ? (
                    !disabled && (
                        <Select
                            isDisabled={disabled}
                            trigger="default"
                            value={memberOptions.find(({ value }) => value === member.role)}
                            options={memberOptions}
                            onChange={option => {
                                if (option) {
                                    changeRole({
                                        role: option.value,
                                        memberId: member.id,
                                        username: member.user.username,
                                    });
                                }
                            }}
                        />
                    )
                ) : (
                    <p className="text-body-subtle">Suspended</p>
                )}
                {!disabled && (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button mode="borderless">
                                <Ellipsis />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent side="bottom" align="end">
                            <DropdownMenuItem
                                onClick={() => {
                                    member.suspended
                                        ? reinstateMember({ memberId: member.id })
                                        : suspendMember({ memberId: member.id });
                                }}
                            >
                                <div className={cn('gap-sm flex', !member.suspended && 'text-red-900')}>
                                    {member.suspended ? (
                                        <>
                                            <UserRoundPlus />
                                            Reinvite Member
                                        </>
                                    ) : (
                                        <>
                                            <UserRoundX />
                                            Suspend Member
                                        </>
                                    )}
                                </div>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )}
            </div>
        </div>
    );
};

const InvitedMemberRow = ({ invite }: { invite: InvitedUser }) => {
    const deleteInviteMutation = useDeleteInvite(invite.id);

    return (
        <div key={invite.id} className="p-md border-subtle flex items-center justify-between border-b last:border-none">
            <div className="flex flex-col">
                <div className="font-medium">{invite.email}</div>
            </div>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button mode="borderless">
                        <Ellipsis />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent side="bottom" align="end">
                    <DropdownMenuItem onClick={() => deleteInviteMutation.mutate({ id: invite.id })}>
                        <div className="gap-sm flex text-red-900">
                            <Trash2 size={16} />
                            Delete Invite
                        </div>
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
};

const Members = () => {
    const { user, setUser } = useUser.getState();
    const orgId = user?.orgMemberships[0].orgId;
    const userUsername = user?.user?.username;
    const isAdmin = user?.orgMemberships[0].role === CnslRole.ADMIN;

    const { fetchNextPage, hasNextPage, isFetching, data } = useInfiniteQuery<GetMembersResponse>({
        queryKey: ['members', 'list'],
        queryFn: ({ pageParam }) =>
            baseFetch<GetMembersResponse>(`/api/v1/members?${qs.stringify({ offset: pageParam })}`, {
                headers: getHeaders(),
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.pagination.remaining === 0 ? null : lastPage.pagination.offset),
    });

    const { data: invitedUsers } = useInvitedUsers();

    const suspend = useMemberSuspend();
    const reinstate = useMemberReinstate();

    /* Endpoint is sorted by suspension, then name so we can split instead jugglig two queries */
    const fullData = data?.pages.flatMap(({ items }) => items) || [];
    const splitIdx = fullData.findIndex(({ member }) => member.suspended);
    const members = splitIdx > 0 ? fullData.slice(0, splitIdx) : fullData;
    const suspended = splitIdx > 0 ? fullData.slice(splitIdx) : [];

    const update = useMemberUpdate();

    const cbs = {
        changeRole: ({ role, memberId, username }: { role: CnslRoleType; memberId: string; username: string }) => {
            void update.mutateAsync({ role, memberId }).then(() => {
                if (username === userUsername) {
                    setUser({
                        ...user!,
                        orgMemberships: user!.orgMemberships.map(m => {
                            if (m.orgId === orgId) {
                                return {
                                    ...m,
                                    role,
                                };
                            }
                            return m;
                        }),
                    });
                }
            });
        },
        suspendMember: suspend.mutate,
        reinstateMember: reinstate.mutate,
    };

    return (
        <>
            <div className="lg:absolute lg:right-0">
                <AddMember disabled={!isAdmin} />
            </div>
            <div className="border-grey rounded-md border-[0.5px]">
                {!!invitedUsers?.invited.length && (
                    <>
                        <div className="bg-bg-overlay p-md border-grey border-y-[0.5px] text-xs font-medium rounded-t-md">
                            Pending Invites
                        </div>
                        <div className="flex flex-col">
                            {invitedUsers.invited.map(invite => (
                                <InvitedMemberRow key={invite.id} invite={invite} />
                            ))}
                        </div>
                    </>
                )}
                <div
                    className={cn('bg-bg-overlay p-md border-grey border-b-[0.5px] text-xs font-medium', {
                        'rounded-t-md': !invitedUsers?.invited.length,
                    })}
                >
                    Active Members
                </div>
                <div className="flex flex-col">
                    {members.map(m => (
                        <MemberRow key={m.member.id} disabled={!isAdmin} {...m} {...cbs} />
                    ))}
                </div>
                {!!suspended.length && (
                    <div className="bg-bg-overlay p-md border-grey border-y-[0.5px] text-xs font-medium">
                        Suspended Members
                    </div>
                )}
                <div className="gap-md flex flex-col">
                    {suspended.map(m => (
                        <MemberRow key={m.member.id} disabled={false} {...m} {...cbs} />
                    ))}
                </div>
            </div>
            <LoadMore
                shouldLoadMore={!isFetching || hasNextPage}
                cb={async () => {
                    await fetchNextPage();
                }}
            />
        </>
    );
};

export default Members;
