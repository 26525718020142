import * as sentry from '@sentry/react';
import type { MeType } from 'lib/models/me';
import posthog from 'posthog-js';
import { create } from 'zustand';

interface Store {
    user: MeType | null;
    setUser: (user: MeType | null) => void;
}

const getActingUser = (user: MeType | null) => {
    if (user?.assumedByUser) {
        return user.assumedByUser;
    } else if (user?.cnslUser) {
        return user.cnslUser;
    }
    return undefined;
};

export const useUser = create<Store>(set => ({
    user: null,
    setUser: (user: MeType | null) => {
        set({ user });

        const actingUser = getActingUser(user);
        if (actingUser && user) {
            posthog.identify(user.distinctId, {
                id: actingUser.id,
                email: actingUser.email,
                displayName: actingUser.displayName,
                firstName: actingUser.firstName,
                lastName: actingUser.lastName,
                department: actingUser.department,
                title: actingUser.title,
                orgId: actingUser.orgId,
                createdAt: actingUser.createdAt,
                updatedAt: actingUser.updatedAt,
            });

            sentry.setUser({
                id: user.distinctId,
                email: actingUser.email,
                displayName: actingUser.displayName,
                firstName: actingUser.firstName,
                lastName: actingUser.lastName,
                department: actingUser.department,
                title: actingUser.title,
                orgId: actingUser.orgId,
            });
        } else {
            posthog.reset();
        }
    },
}));

export const getOrgMembership = (user: MeType | null) => {
    return user?.orgMemberships?.[0];
};
