import { useMutation } from '@lib/hooks/useMutation';
import type { DefaultError } from '@tanstack/react-query';

export const useDeleteOrgPermanently = () => {
    return useMutation<{ success: boolean }, DefaultError, void>({
        url: `/api/v1/org/delete-org-permanent`,
        method: 'PUT',
        successToast: {
            message: 'Organization permanently deleted',
        },
        errorToast: {
            message: 'Problem deleting organization',
        },
    });
};
