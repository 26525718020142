import { SelectAsync } from '@/components/ui/select/index';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import { IntegrationId } from 'lib/integration';
import type { PaginatedListResponse } from 'lib/models/pagination';
import type { Slackchannel } from 'lib/prisma/types';
import qs from 'qs';
import type { GroupBase, SingleValue } from 'react-select';
import type { LoadOptions } from 'react-select-async-paginate';
import { AppControl } from './ui/select/AppControl';

export interface SlackSelectOpt {
    label: string;
    value: string;
}

export type SlackSelectChangePayload = SingleValue<SlackSelectOpt>;

interface SlackSelectProps {
    selected?: SlackSelectOpt | Array<SlackSelectOpt>;
    isMulti?: boolean;
    onChange?: (v: SlackSelectChangePayload) => void;
    isDisabled?: boolean;
}

const SlackControl = AppControl(IntegrationId.Slack);

export const SlackChannelSelect = ({ selected, onChange, isDisabled }: SlackSelectProps) => {
    return (
        <SelectAsync
            isMulti={false}
            defaultValue={selected}
            value={selected || null}
            isDisabled={isDisabled}
            placeholder="Select a Slack channel"
            components={{
                Control: SlackControl,
            }}
            loadOptions={
                (async (search, _, additional) => {
                    const res = await baseFetch<PaginatedListResponse<Slackchannel>>(
                        `/api/v1/slack/channels?${qs.stringify({ search, cursor: additional?.cursor })}`,
                        {
                            headers: getHeaders(),
                        },
                    );

                    return {
                        options: res.items.map(value => ({
                            label: value.name,
                            value: value.id,
                        })),
                        hasMore: !!res.pagination.nextCursor,
                        additional: {
                            cursor: res.pagination.nextCursor,
                        },
                    };
                }) as LoadOptions<
                    { label: string; value: string },
                    GroupBase<{ label: string; value: string }>,
                    { cursor: string | undefined }
                >
            }
            onChange={onChange}
        />
    );
};
