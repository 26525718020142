import ComboboxPopover from '@/components/ComboboxPopover';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { useJiraIssueTypes, useNamespaceOptions } from '@/hooks/queries/useHelpdesk';
import { useHelpDeskProvidersStatus } from '@/hooks/queries/useHelpdesk';
import { cn } from '@/lib/styling';
import { useHelpDeskStore } from '@/stores/useHelpDesk';
import { Label } from '@radix-ui/react-label';
import { getProviderAssets } from 'lib/3p';
import { TicketProvider, type TicketProviderType } from 'lib/prisma/enums';
import { useMemo } from 'react';

export const HelpDeskFormSelect = () => {
    const { data } = useNamespaceOptions();
    const store = useHelpDeskStore();
    const jiraIssueTypes = useJiraIssueTypes(store.namespace ?? '');

    if (!store.provider) return null;
    const { name } = getProviderAssets(store.provider);

    const namespaceOpts = data?.[store.provider];

    const namespaceOptions = namespaceOpts?.options ?? [];

    const selectedOption = useMemo(() => {
        const foundOpt = namespaceOptions.find(v => v.value === store.namespace);
        return foundOpt?.value;
    }, [namespaceOptions, store.namespace]);

    const selectedSubTicketType = useMemo(() => {
        const foundOpt = jiraIssueTypes.find(v => v.id === store.ticketSubType);
        return foundOpt?.id;
    }, [jiraIssueTypes, store.ticketSubType]);

    return (
        <div className="flex flex-col gap-md">
            <Label className="font-medium">
                Select a {name} {namespaceOpts?.displayName}
            </Label>
            <ComboboxPopover
                key={store.provider + store.namespace}
                className="max-w-xs"
                constrainWidth
                onChange={v => {
                    store.setNamespace(v as string);
                }}
                defaultValue={selectedOption}
                value={selectedOption}
                options={namespaceOptions}
            />
            {store.provider === TicketProvider.jira && (
                <>
                    <Label className="font-medium">Select an Issue Type</Label>
                    <ComboboxPopover
                        className="max-w-xs"
                        constrainWidth
                        onChange={v => {
                            store.setTicketSubType(v);
                        }}
                        defaultValue={selectedSubTicketType}
                        value={selectedSubTicketType}
                        options={jiraIssueTypes.map(t => ({ label: t.name ?? '', value: t.id ?? '' }))}
                    />
                </>
            )}
        </div>
    );
};

export const HelpDeskPicker = () => {
    const helpDeskProviders = useHelpDeskProvidersStatus().filter(p => p.enabled);
    const store = useHelpDeskStore();
    return (
        <section className="flex flex-col gap-md">
            <div>
                <h3 className="text-base">Select a help desk</h3>
                <p className="text-body-subtle-hover">
                    You can connect different providers to direct different request types to the appropriate tool
                </p>
            </div>
            <RadioGroup
                className="grid lg:grid-cols-2 xl:grid-cols-3 gap-lg"
                value={store.provider}
                onValueChange={p => store.setProvider(p as TicketProviderType)}
            >
                {helpDeskProviders.map(p => (
                    <Label
                        className={cn(
                            'font-medium flex gap-md justify-between border border-grey rounded-md p-lg hover:bg-bg-surface transition-colors',
                            store.provider === p.provider ? 'border-bg-blue-secondary border-2 bg-bg-blue-primary' : '',
                        )}
                        htmlFor={p.provider}
                        key={p.provider}
                    >
                        <div className="flex items-center gap-md">
                            <img src={getProviderAssets(p.provider).logo} alt={p.provider} className="size-5" />
                            {getProviderAssets(p.provider).name}
                        </div>
                        <RadioGroupItem value={p.provider} className="mt-[3.5px]" id={p.provider} />
                    </Label>
                ))}
            </RadioGroup>
            {store.provider && <HelpDeskFormSelect />}
        </section>
    );
};
