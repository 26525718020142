import { useJiraGetOAuthUrl } from '@/hooks/queries/useOAuth';
import { IntegrationId } from 'lib/integration';

export const useProviderEndpoints = () => {
    const { data: jiraOAuthUrl } = useJiraGetOAuthUrl();

    const getProviderPath = (provider: IntegrationId, name: string) => {
        let providerPath = `/settings/organization/integrations/${name.toLowerCase()}`;

        switch (provider) {
            case IntegrationId.Slack: {
                const clientId = import.meta.env.VITE_SLACK_CLIENT_ID;
                const redirectUri = import.meta.env.VITE_SLACK_REDIRECT_URI;
                providerPath = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=chat:write,chat:write.customize,commands,im:history,im:read,im:write,metadata.message:read,users:read,users:read.email,channels:history,channels:join,groups:history,groups:read,groups:write,groups:write.invites,mpim:history,mpim:read,mpim:write,channels:read,channels:manage,channels:write.invites&user_scope=`;
                if (redirectUri) {
                    providerPath += `&redirect_uri=${redirectUri}`;
                }
                break;
            }
            case IntegrationId.Notion:
                providerPath = import.meta.env.VITE_NOTION_AUTHORIZATION_URL;
                break;
            case IntegrationId.Jira:
                providerPath = jiraOAuthUrl?.url ?? '';
                break;
        }

        return providerPath;
    };

    return {
        getProviderPath,
    };
};
