import { cva } from 'class-variance-authority';

const loaderVariants = cva('', {
    variants: {
        size: {
            sm: 'size-3', // 12 × 12
            default: 'size-4', // 16 × 16
            lg: 'size-6', // 24 × 24
            xl: 'size-8', // 32 × 32
        },
    },
    defaultVariants: {
        size: 'default',
    },
});

export default loaderVariants;
