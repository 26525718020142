import { Button } from '@/components/ui/button/button';
import { useDefaultOrgchartSettingsPut, useOrgChartConfig } from '@hooks/mutations/settings/useDefaultOrgchartSettings';
import { SectionHeader } from '@pages/settings/components/Approvals/Approvals';
import { useOrgchartSettings } from '@stores/useOrgchartSettings.ts';
import { Input } from '@ui/input.tsx';
import { Select } from '@ui/select';
import { OrgchartProviderEnum } from 'lib/models/settings/orgchart';
import { Network } from 'lucide-react';
import { useEffect } from 'react';

const orgchartProviders: { label: string; value: OrgchartProviderEnum }[] = [
    {
        label: 'Okta',
        value: OrgchartProviderEnum.OKTA,
    },
    {
        label: 'HRIS',
        value: OrgchartProviderEnum.HRIS,
    },
];
export const OrgChartSettings = () => {
    const { data: orgChartConfig } = useOrgChartConfig();
    const { mutate: updateSettings } = useDefaultOrgchartSettingsPut();

    const store = useOrgchartSettings();

    useEffect(() => {
        if (orgChartConfig?.config) {
            store.setSettings(orgChartConfig.config);
        } else {
            store.setSettings({
                provider: OrgchartProviderEnum.HRIS,
            });
        }
    }, [orgChartConfig, store.setSettings]);

    return (
        <div className="flex flex-col gap-xl">
            <section className="border-grey border rounded-md">
                <SectionHeader
                    heading="Org chart"
                    description="Console uses your org chart to route (and escalate) requests to the right person"
                    icon={<Network />}
                    iconBg="bg-bg-orange-secondary text-body-white"
                />
                <div className="flex flex-col gap-md p-lg">
                    Where would you like Console to pull your org chart from
                    <Select
                        className="lg:w-2/5 max-w-sm"
                        options={orgchartProviders}
                        value={orgchartProviders.find(provider => provider.value === store.settings?.provider)}
                        onChange={v => {
                            store.setSettings({ provider: v?.value, field: undefined });
                            if (v?.value === OrgchartProviderEnum.HRIS) {
                                updateSettings({
                                    provider: OrgchartProviderEnum.HRIS,
                                });
                            }
                        }}
                    />
                    {store.settings && store.settings.provider === 'OKTA' && (
                        <>
                            Which field maps to the manager's email
                            <Input
                                className="lg:w-2/5 max-w-sm"
                                value={store.settings?.field}
                                onChange={v =>
                                    store.setSettings({ field: v.target.value, provider: store.settings?.provider })
                                }
                                placeholder="Select email field"
                            />
                            <Button
                                className="w-max"
                                onClick={() => {
                                    updateSettings({
                                        field: store.settings?.field,
                                        provider: store.settings?.provider as OrgchartProviderEnum,
                                    });
                                }}
                            >
                                Save
                            </Button>
                        </>
                    )}
                </div>
            </section>
        </div>
    );
};
