import { useCallback, useLayoutEffect, useRef, useState } from 'react';

type Maybe<T> = T | null;

interface ElapsedTimeProps {
    /** duration and startAt seconds */
    duration?: number;
    startAt?: number;
    /** interval that elapsedTime is updated */
    updateInterval?: number;
    /** When complete, you can return shouldRepeat to have it restart */
    onComplete?: (totalElapsedTime: number) =>
        | {
              shouldRepeat?: boolean;
          }
        | undefined;
}

interface ElapsedTimeReturnType {
    elapsedTime: number;
    cancel: () => void;
    reset: (newStartAt?: number) => void;
}

export const useElapsedTime = ({
    duration,
    startAt = 0,
    updateInterval = 0,
    onComplete,
}: ElapsedTimeProps): ElapsedTimeReturnType => {
    const [displayTime, setDisplayTime] = useState(startAt);
    const elapsedTimeRef = useRef(0);
    const startAtRef = useRef(startAt);
    const totalElapsedTimeRef = useRef(startAt * -1000); // in milliseconds
    const requestRef = useRef<Maybe<number>>(null);
    const previousTimeRef = useRef<Maybe<number>>(null);
    const repeatTimeoutRef = useRef<Maybe<NodeJS.Timeout>>(null);

    const loop = useCallback(
        (time: number) => {
            const timeSec = time / 1000;
            if (previousTimeRef.current === null) {
                previousTimeRef.current = timeSec;
                requestRef.current = requestAnimationFrame(loop);
                return;
            }

            // get current elapsed time
            const deltaTime = timeSec - previousTimeRef.current;
            const currentElapsedTime = elapsedTimeRef.current + deltaTime;

            // update refs with the current elapsed time
            previousTimeRef.current = timeSec;
            elapsedTimeRef.current = currentElapsedTime;

            // set current display time by adding the elapsed time on top of the startAt time
            const currentDisplayTime =
                startAtRef.current +
                (updateInterval === 0
                    ? currentElapsedTime
                    : ((currentElapsedTime / updateInterval) | 0) * updateInterval);

            const totalTime = startAtRef.current + currentElapsedTime;
            const isCompleted = typeof duration === 'number' && totalTime >= duration;
            const newDisplayTime = isCompleted ? duration! : currentDisplayTime;
            if (newDisplayTime !== displayTime) {
                setDisplayTime(newDisplayTime);
            }

            // repeat animation if not completed
            if (!isCompleted) {
                requestRef.current = requestAnimationFrame(loop);
            }
        },
        [duration, updateInterval, displayTime],
    );

    const cleanup = () => {
        requestRef.current && cancelAnimationFrame(requestRef.current);
        repeatTimeoutRef.current && clearTimeout(repeatTimeoutRef.current);
        previousTimeRef.current = null;
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: avoid rerenders
    const reset = useCallback(
        (newStartAt?: number) => {
            cleanup();

            elapsedTimeRef.current = 0;
            const nextStartAt = typeof newStartAt === 'number' ? newStartAt : startAt;
            startAtRef.current = nextStartAt;
            setDisplayTime(nextStartAt);

            requestRef.current = requestAnimationFrame(loop);
        },
        [loop, startAt],
    );

    useLayoutEffect(() => {
        if (duration && displayTime >= duration) {
            totalElapsedTimeRef.current += duration * 1000;

            const { shouldRepeat = false } = onComplete?.(totalElapsedTimeRef.current / 1000) || {};

            if (shouldRepeat) {
                repeatTimeoutRef.current = setTimeout(reset, 0);
            }
        }
    }, [displayTime, duration, onComplete, reset]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: avoid rerenders
    useLayoutEffect(() => {
        requestRef.current = requestAnimationFrame(loop);

        return cleanup;
    }, [duration, loop, updateInterval]);

    return { elapsedTime: displayTime, cancel: cleanup, reset };
};
