import { ORG_ID_HEADER_KEY } from 'lib/constants';

import { useUser } from '@/stores/useUser';

export const getHeaders = (initHeaders?: Record<string, string>) => {
    const userStore = useUser.getState();
    const orgId = userStore.user?.orgMemberships[0].orgId;
    const headers: Record<string, string> = initHeaders ?? {};
    if (orgId) {
        headers[ORG_ID_HEADER_KEY] = orgId;
    }
    return headers;
};
