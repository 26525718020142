import type { DefaultError, UseQueryOptions as PrimitiveUseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { baseFetch } from '@lib/baseFetch';
import { getHeaders } from '@lib/getHeaders';
import { useQuery as useQueryPrimitive } from '@tanstack/react-query';

export interface UseQueryOptions<TData, TError>
    extends Omit<Omit<PrimitiveUseQueryOptions<TData, TError>, 'queryKey'>, 'queryFn'> {
    url: string;
    headers?: Record<string, string>;
    queryKey?: string[];
}

export const useQuery = <TData = unknown, TError = DefaultError>(
    options: UseQueryOptions<TData, TError>,
): UseQueryResult<TData, TError> => {
    const { url, headers, queryKey, ...rest } = options;
    const allHeaders = getHeaders(headers);

    return useQueryPrimitive<TData, TError>({
        retry: 1,
        refetchOnWindowFocus: false,
        queryKey: queryKey ?? [options.url],
        queryFn: () => {
            return baseFetch<TData>(url, { headers: allHeaders });
        },
        ...rest,
    });
};
