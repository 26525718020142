import { type DefaultPolicySettings, DefaultPolicySettingsSchema } from 'lib/models/app_policy';
import { AppPolicyDuration, AppPolicyReviewStrategy, type AppPolicyReviewStrategyType } from 'lib/prisma/enums';
import { safeParse } from 'valibot';
import { create } from 'zustand';

const invalidExplicitConfig = (
    strategy: AppPolicyReviewStrategyType | undefined,
    userIds: string[],
    groupIds: string[],
) => {
    if (strategy === AppPolicyReviewStrategy.EXPLICIT && userIds.length === 0 && groupIds.length === 0) {
        return true;
    }
    return false;
};

interface Store {
    currentSettings: DefaultPolicySettings | null;
    settings: DefaultPolicySettings | null;
    setSettings: (settings: Partial<DefaultPolicySettings>) => void;
    init: (settings: DefaultPolicySettings) => void;
    isDirty: () => boolean;
}

export const useApprovalSettings = create<Store>((set, get) => ({
    currentSettings: null,
    settings: null,
    setSettings: (payload: Partial<DefaultPolicySettings>) => {
        if (get().currentSettings === null) {
            return;
        }
        set(state => {
            if (
                payload.defaultPolicyReviewStrategy &&
                payload.defaultPolicyReviewStrategy !== AppPolicyReviewStrategy.EXPLICIT
            ) {
                payload.defaultPolicyUserReviewerIds = [];
                payload.defaultPolicyGroupReviewerIds = [];
            }
            const dursWithOptions = new Set<string>([
                AppPolicyDuration.USER_DEFINED,
                AppPolicyDuration.USER_DEFINED_RANGE,
            ]);
            if (payload.defaultPolicyDuration && !dursWithOptions.has(payload.defaultPolicyDuration)) {
                payload.defaultPolicyDurationOptions = [];
            }
            if (
                payload.pendingApprovalEscalationStrategy &&
                payload.pendingApprovalEscalationStrategy !== AppPolicyReviewStrategy.EXPLICIT
            ) {
                payload.pendingApprovalEscalationUserIds = [];
                payload.pendingApprovalEscalationGroupIds = [];
            }

            const userIds = payload.defaultPolicyUserReviewerIds ?? state.settings?.defaultPolicyUserReviewerIds ?? [];
            const groupIds =
                payload.defaultPolicyGroupReviewerIds ?? state.settings?.defaultPolicyGroupReviewerIds ?? [];
            const strategy = payload.defaultPolicyReviewStrategy ?? state.settings?.defaultPolicyReviewStrategy;
            if (invalidExplicitConfig(strategy, userIds, groupIds)) {
                payload.defaultPolicyReviewStrategy = AppPolicyReviewStrategy.APP_OWNERS;
            }

            if (state.settings) {
                return { settings: { ...state.settings, ...payload } };
            }
            const parsed = safeParse(DefaultPolicySettingsSchema, payload);
            if (parsed.success) {
                return { settings: parsed.output };
            }
            return state;
        });
    },
    init: (settings: DefaultPolicySettings) => {
        set({ currentSettings: settings, settings: settings });
    },
    isDirty: () => {
        if (get().currentSettings === null || get().settings === null) {
            return false;
        }
        return JSON.stringify(get().currentSettings) !== JSON.stringify(get().settings);
    },
}));
