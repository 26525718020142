import * as React from 'react';
import { useImperativeHandle } from 'react';

import useCursorToEnd from '@/hooks/useCursorToEnd';
import { cn } from '@/lib/styling';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    icon?: React.ReactNode;
}

export const baseInputStyle =
    'flex py-sm h-8 w-full rounded-[6px] border-[0.5px] bg-bg border-grey px-md ring-offset-background file:border-0 file:bg-transparent file:font-medium placeholder:text-body-subtle focus-visible:outline-none focus-visible:border-grey-selected hover:border-grey-hover disabled:cursor-not-allowed disabled:opacity-50';

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, autoFocus, icon, ...props }, ref) => {
    const cursorRef = useCursorToEnd<HTMLInputElement>();
    useImperativeHandle(ref, () => cursorRef.current as HTMLInputElement);

    React.useEffect(() => {
        if (autoFocus && cursorRef.current) {
            cursorRef.current.focus();
        }
    }, [autoFocus, cursorRef.current]);

    return (
        <div className="relative flex items-center w-full">
            {icon && <div className="absolute left-md">{icon}</div>}
            <input
                type={type}
                className={cn(baseInputStyle, icon ? 'pl-8' : '', className)}
                ref={cursorRef}
                autoComplete="off"
                {...props}
            />
        </div>
    );
});
Input.displayName = 'Input';

export { Input };
