import type * as LabelPrimitive from '@radix-ui/react-label';
import { Slot } from '@radix-ui/react-slot';
import { Label, useFormField } from '@ui/form';
import { Info } from 'lucide-react';
import * as React from 'react';
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { Controller, FormProvider } from 'react-hook-form';

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/styling';

interface FormItemContextValue {
    id: string;
}

interface FormFieldContextValue<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
    name: TName;
}

const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);

const Form = FormProvider;

const FormFieldContext = React.createContext<FormFieldContextValue>({} as FormFieldContextValue);

const FormField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    ...props
}: ControllerProps<TFieldValues, TName>) => {
    const value = React.useMemo(() => ({ name: props.name }), [props.name]);
    return (
        <FormFieldContext.Provider value={value}>
            <Controller {...props} />
        </FormFieldContext.Provider>
    );
};

const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        const id = React.useId();

        const value = React.useMemo(() => ({ id }), [id]);
        return (
            <FormItemContext.Provider value={value}>
                <div ref={ref} className={cn('space-y-sm', className)} {...props} />
            </FormItemContext.Provider>
        );
    },
);
FormItem.displayName = 'FormItem';

const FormLabel = React.forwardRef<
    React.ElementRef<typeof LabelPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & { required?: boolean; info?: string }
>(({ className, required, info, ...props }, ref) => {
    const { formItemId } = useFormField();

    return (
        <Label ref={ref} className={className} htmlFor={formItemId} {...props}>
            <span className="truncate font-medium">{props.children}</span>
            {required && <span className="text-body-red-primary mr-sm">*</span>}
            {info && (
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Info className="text-body-subtle hover:text-body-grey-primary size-3 cursor-help transition duration-100 ease-out" />
                    </TooltipTrigger>
                    <TooltipContent>{info}</TooltipContent>
                </Tooltip>
            )}
        </Label>
    );
});
FormLabel.displayName = 'FormLabel';

const FormControl = React.forwardRef<React.ElementRef<typeof Slot>, React.ComponentPropsWithoutRef<typeof Slot>>(
    ({ ...props }, ref) => {
        const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

        return (
            <Slot
                ref={ref}
                id={formItemId}
                aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
                aria-invalid={!!error}
                {...props}
            />
        );
    },
);
FormControl.displayName = 'FormControl';

const FormDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
    ({ className, ...props }, ref) => {
        const { formDescriptionId } = useFormField();

        return <p ref={ref} id={formDescriptionId} className={cn('text-body-subtle', className)} {...props} />;
    },
);
FormDescription.displayName = 'FormDescription';

const FormMessage = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
    ({ className, children, ...props }, ref) => {
        const { error, formMessageId } = useFormField();
        const body = error ? String(error?.message) : children;

        if (!body) {
            return null;
        }

        return (
            <p
                ref={ref}
                id={formMessageId}
                className={cn('text-xs px-md pb-md text-body-red-primary', className)}
                {...props}
            >
                {body}
            </p>
        );
    },
);
FormMessage.displayName = 'FormMessage';

export {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormFieldContext,
    FormItem,
    FormItemContext,
    FormLabel,
    FormMessage,
};
