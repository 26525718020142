import { Button } from '@/components/ui/button/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator/separator';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useCategoriesDelete } from '@/hooks/mutations/categories/useCategoryDelete';
import { useCategoriesPost } from '@/hooks/mutations/categories/useCategoryPost';
import { useCategoriesUpdate } from '@/hooks/mutations/categories/useCategoryPut';
import { useCategories } from '@/hooks/queries/useCategories';
import { cn } from '@/lib/styling';
import { Color } from 'lib/enums';
import type { CreateCategorySchemaType } from 'lib/models/category';
import type { RequestFilters } from 'lib/models/cnsl_request';
import type { CnslCategory } from 'lib/prisma/types';
import { CircleX, CornerDownRight, Dot, Ellipsis, Plus, Search, Trash2 } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

const DeleteCategoryDialog = ({
    isOpen,
    setIsOpen,
    onSubmit,
}: { isOpen: boolean; setIsOpen: (isOpen: boolean) => void; categoryId: string; onSubmit: () => void }) => {
    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Are you sure you want to delete this category?</DialogTitle>
                    <DialogDescription>Deleting a category will remove it from your requests</DialogDescription>
                </DialogHeader>

                <DialogFooter>
                    <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                    <Button onClick={() => onSubmit()} variant="red" mode="dark">
                        Delete
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const NewCategory = ({
    allNames,
    onSubmit,
    onCancel,
}: {
    allNames: Set<string>;
    onSubmit: (categoryForm: CreateCategorySchemaType) => void;
    onCancel: () => void;
}) => {
    const [color, setColor] = useState(Object.values(Color)[Math.floor(Math.random() * Object.values(Color).length)]);
    const [name, setName] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (allNames.has(name)) {
            setError('Name must be unique');
        } else {
            setError('');
        }
    }, [name, allNames]);

    return (
        <div className="flex items-center justify-between w-full bg-bg-overlay rounded-[6px] p-sm h-8">
            <CategoryColorPicker color={color} onColorChange={setColor} />
            <div className="relative grow flex items-center gap-md">
                <Input
                    autoFocus
                    className={cn(
                        'bg-bg-grey-primary border-[0.5px] border-transparent h-6',
                        error ? 'border border-red' : 'hover:border-grey',
                    )}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    onBlur={e => {
                        if (e.target.value !== '') {
                            onSubmit({ name, color });
                        } else {
                            onCancel();
                        }
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter' && name !== '') {
                            onSubmit({ name, color });
                        } else if (e.key === 'Enter' || e.key === 'Escape') {
                            onCancel();
                        }
                    }}
                />
                {error && <p className="text-nowrap text-body-red-primary text-xs">{error}</p>}
            </div>
        </div>
    );
};

const ExistingCategory = ({
    category,
    allNames,
}: {
    allNames: Set<string>;
    category: CnslCategory & {
        _count: { cnsl_request: number };
        children?: (CnslCategory & { _count: { cnsl_request: number } })[];
    };
}) => {
    const navigate = useNavigate();
    const { mutate: updateCategory } = useCategoriesUpdate(category.id);
    const { mutate: deleteCategory } = useCategoriesDelete(category.id);
    const { mutate: createCategory } = useCategoriesPost();
    const [name, setName] = useState(category.name);
    const [isFocused, setIsFocused] = useState(false);
    const [error, setError] = useState('');
    const [subCategoryFormOpen, setSubCategoryFormOpen] = useState(false);
    const [_, setFilters] = useLocalStorage<RequestFilters>('REQUESTS_FILTER_V3', {});
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const isSubCategory = category.parent_id !== null;
    const count = category._count.cnsl_request;
    const restNames = new Set([...allNames].filter(n => n !== category.name));

    const inputRef = useRef<HTMLInputElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (restNames.has(name)) {
            setError('Category name must be unique');
        } else {
            setError('');
        }
    }, [name, restNames]);

    useEffect(() => {
        if (inputRef.current && spanRef.current) {
            if (isFocused) {
                inputRef.current.style.width = '100%';
            } else {
                inputRef.current.style.width = `${spanRef.current.offsetWidth + 18}px`;
            }
        }
    }, [isFocused]);

    return (
        <>
            <div className="flex flex-col w-full">
                <div className="flex items-center justify-between hover:bg-bg-overlay rounded-[6px] p-sm h-8">
                    <div className="flex items-center w-full">
                        <CategoryColorPicker
                            color={category.color}
                            onColorChange={color => updateCategory({ id: category.id, name: category.name, color })}
                        />
                        <div className={cn('flex items-center gap-md relative', isFocused ? 'grow' : '')}>
                            <Input
                                ref={inputRef}
                                className={cn(
                                    'hover:bg-bg-grey-primary bg-transparent focus:bg-bg-grey-primary border-[0.5px] border-transparent h-6',
                                    error ? 'border border-red' : 'hover:border-grey',
                                )}
                                value={name}
                                onChange={e => setName(e.target.value)}
                                onFocus={() => setIsFocused(true)}
                                onBlur={e => {
                                    setIsFocused(false);
                                    if (e.target.value !== '' && e.target.value !== category.name) {
                                        updateCategory({
                                            id: category.id,
                                            name: e.target.value,
                                            color: category.color,
                                        });
                                    }
                                }}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        inputRef.current?.blur();
                                    } else if (e.key === 'Escape') {
                                        setName(category.name);
                                        inputRef.current?.blur();
                                    }
                                }}
                            />
                            {error && <p className="text-nowrap text-body-red-primary text-xs">{error}</p>}
                            <span ref={spanRef} className="absolute top-0 left-0 invisible whitespace-pre">
                                {name}
                            </span>
                        </div>
                        {!isFocused && (
                            <>
                                <Dot />
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Button
                                            mode="borderless"
                                            size="sm"
                                            className="text-body-subtle-hover cursor-pointer hover:text-body-grey-primary text-xs"
                                            onClick={() => {
                                                setFilters({ category: [category.id] });
                                                navigate(`/requests`);
                                            }}
                                        >
                                            {count ? `${count} request${count > 1 ? 's' : ''}` : 'No requests'}
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent>Show "{name}" requests</TooltipContent>
                                </Tooltip>
                            </>
                        )}
                    </div>
                    <div className="flex items-center gap-sm">
                        {!isSubCategory && (
                            <Button
                                mode="borderless"
                                size="sm"
                                className="text-body-subtle hover:text-body-grey-primary"
                                onClick={() => setSubCategoryFormOpen(true)}
                            >
                                <CornerDownRight />
                            </Button>
                        )}

                        <DropdownMenu>
                            <DropdownMenuTrigger className="h-6">
                                <Button
                                    mode="borderless"
                                    size="sm"
                                    className="text-body-subtle hover:text-body-grey-primary"
                                >
                                    <Ellipsis />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuItem
                                    onClick={() => setIsDeleteDialogOpen(true)}
                                    className="text-body-red-primary"
                                >
                                    <Trash2 />
                                    Delete
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>
                <div className="flex">
                    <div className="w-[1px] bg-bg-grey-primary mx-[15px]" />
                    <div className="flex flex-col w-full">
                        {subCategoryFormOpen && (
                            <NewCategory
                                allNames={allNames}
                                onSubmit={async form => {
                                    await createCategory({ name: form.name, color: form.color, parentId: category.id });
                                    setSubCategoryFormOpen(false);
                                }}
                                onCancel={() => setSubCategoryFormOpen(false)}
                            />
                        )}
                        {category.children?.map(child => (
                            <ExistingCategory allNames={allNames} category={child} key={child.id} />
                        ))}
                    </div>
                </div>
            </div>
            <DeleteCategoryDialog
                isOpen={isDeleteDialogOpen}
                setIsOpen={setIsDeleteDialogOpen}
                categoryId={category.id}
                onSubmit={() => deleteCategory()}
            />
        </>
    );
};

const CategoryColorPicker = ({ color, onColorChange }: { color: string; onColorChange: (color: string) => void }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState(color);

    return (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger>
                <Button mode="borderless" size="sm" className="w-6">
                    <div className={cn('size-md rounded-[2px] shrink-0', `bg-[${selectedColor}]`)} />
                </Button>
            </PopoverTrigger>
            <PopoverContent>
                <div className="w-[180px] p-sm overflow-x-auto no-scrollbar flex">
                    {Object.values(Color).map(color => (
                        <Button
                            key={color}
                            mode="borderless"
                            size="sm"
                            className="w-6"
                            onClick={() => {
                                setSelectedColor(color);
                                onColorChange(color);
                                setIsOpen(false);
                            }}
                        >
                            <div key={color} className={cn('size-md rounded-[2px]', `bg-[${color}]`)} />
                        </Button>
                    ))}
                </div>
            </PopoverContent>
        </Popover>
    );
};

const Categories = () => {
    const { data } = useCategories();
    const { mutate: createCategory } = useCategoriesPost();
    const [searchTerm, setSearchTerm] = useState('');
    const [showNewCategory, setShowNewCategory] = useState(false);

    const allNames = useMemo(() => {
        return new Set(data?.categories.flatMap(c => [c.name, ...c.children.map(child => child.name)]));
    }, [data]);

    const filteredCategories = useMemo(() => {
        return data?.categories.filter(c =>
            [c.name, ...(c.children?.map(c => c.name) || [])].join('').toLowerCase().includes(searchTerm.toLowerCase()),
        );
    }, [data, searchTerm]);

    return (
        <div className="flex flex-col gap-2xl">
            <div className="flex gap-md">
                <div className="pl-sm relative flex items-center rounded-sm border-grey border-[0.5px] flex-grow">
                    <Search className="opacity-50" />
                    <Input
                        id="user-search"
                        value={searchTerm}
                        placeholder="Search"
                        onInput={e => setSearchTerm(e.currentTarget.value)}
                        className="border-none"
                    />
                    {searchTerm.length > 0 && (
                        <button className="right-sm absolute">
                            <CircleX onClick={() => setSearchTerm('')} />
                        </button>
                    )}
                </div>
                <Button onClick={() => setShowNewCategory(true)}>
                    <Plus /> Add Category
                </Button>
            </div>
            <Separator />
            <div className="flex flex-col">
                {showNewCategory && (
                    <NewCategory
                        allNames={allNames}
                        onSubmit={async cat => {
                            await createCategory(cat);
                            setShowNewCategory(false);
                        }}
                        onCancel={() => setShowNewCategory(false)}
                    />
                )}
                {filteredCategories?.map(category => (
                    <ExistingCategory allNames={allNames} category={category} key={category.id} />
                ))}
            </div>
        </div>
    );
};

export default Categories;
