import { baseFetch } from '@/lib/baseFetch';
import { useMutation } from '@/lib/hooks/useMutation';
import { ME_ROUTE } from '@/routes';
import { ROOT } from '@/routes/paths';
import { useUser } from '@/stores/useUser';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { GetMeResponse } from 'lib/models/me';
import type { OrgOnboardingStatusType } from 'lib/prisma/enums';
import { useNavigate } from 'react-router-dom';

export const onboardingStatusUpdateQueryKey = '/api/v1/org/onboarding/status';

export const useOnboardingStatusUpdate = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { setUser } = useUser.getState();

    return useMutation<{ success: boolean }, DefaultError, { status: OrgOnboardingStatusType }>({
        url: onboardingStatusUpdateQueryKey,
        method: 'POST',
        onSuccess: async () => {
            queryClient.refetchQueries({ queryKey: [onboardingStatusUpdateQueryKey] });

            // refetch the user, so that we can get the current onboarding status on the org
            const data = await baseFetch<GetMeResponse>(ME_ROUTE);
            setUser(data.me);

            navigate(ROOT);
        },
    });
};
