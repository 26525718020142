import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { cn } from '@lib/styling';
import type { FlagKey } from 'lib/flags/keys';
import type { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Badge } from '@/components/ui/badge/badge';
import { getOrgMembership } from '@/stores/useUser';
import { bookDemoPopup } from '../lib/calendly';
import { useUser } from '../stores/useUser';

type MenuItem = {
    name: ReactNode;
    pathname: string;
    matchPathExactly?: boolean;
    count?: number;
    flag?: FlagKey;
    disabledForNerfed?: boolean;
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
};

export type NavMenuItem = MenuItem | { section: string };

const pathMatches = (pathname: string, item: MenuItem) => {
    if (item.matchPathExactly) {
        return pathname === item.pathname;
    }
    return pathname.startsWith(item.pathname);
};

export const NavMenu = ({ items }: { items: NavMenuItem[] }) => {
    const { pathname } = useLocation();
    const { user } = useUser.getState();
    const nerfed = getOrgMembership(user)?.nerfed;
    const availableItems = items.filter(item => {
        if ('flag' in item && item.flag) {
            return useFeatureFlag(item.flag);
        }
        return true;
    });

    return (
        <div className="gap-[2px] p-sm flex h-full flex-col overflow-auto font-medium">
            {availableItems.map(item => {
                if ('section' in item) {
                    return (
                        <div key={item.section} className="text-xs text-body-subtle p-sm first:mt-[2px] mt-lg">
                            {item.section}
                        </div>
                    );
                }

                const { Icon } = item;
                const isActive = pathMatches(pathname, item);
                const className = cn(
                    'px-md py-sm h-7 hover:bg-bg-grey-primary-hover gap-md flex items-center rounded-sm',
                    isActive ? 'bg-bg-grey-primary-hover' : '',
                );

                const isDisabled = item.disabledForNerfed && nerfed;
                const handleDisabledClick = (e: React.MouseEvent) => {
                    e.preventDefault();
                    bookDemoPopup();
                };

                return (
                    <Link
                        className={cn(className, isDisabled && 'opacity-50')}
                        key={`${item.name}`}
                        to={item.pathname}
                        onClick={isDisabled ? handleDisabledClick : undefined}
                    >
                        <Icon className="size-lg" />
                        <div className="flex w-full items-baseline justify-between">
                            <div>{item.name}</div>
                            {item.count !== undefined && (
                                <Badge className={cn('min-w-[26px]', isActive && 'border-steel-800')}>
                                    {item.count}
                                </Badge>
                            )}
                        </div>
                    </Link>
                );
            })}
        </div>
    );
};
